import React, { useState, useRef } from 'react';
import { Card, CardBody, Button } from '../ui/ScraperUI';
import CommonPainPointsChart from './CommonPainPointsChart';
import IndustryTrendsChart from './IndustryTrendsChart';
import CompanyComparisonChart from './CompanyComparisonChart';
import { Category, CompanyData, Subcategory } from './types';

interface MetaAnalysisPageProps {
	darkMode: boolean;
	setDarkMode: (darkMode: boolean) => void;
}

const MetaAnalysisPage: React.FC<MetaAnalysisPageProps> = ({ darkMode, setDarkMode }) => {
	// File handling state
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [parsedData, setParsedData] = useState<CompanyData[]>([]);
	const [isAnalyzing, setIsAnalyzing] = useState(false);
	const [analysisComplete, setAnalysisComplete] = useState(false);
	const [progress, setProgress] = useState(0);
	const fileInputRef = useRef<HTMLInputElement>(null);

	// Active tab state
	const [activeTab, setActiveTab] = useState('overview');

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const filesArray = Array.from(e.target.files);
			setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
		}
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.files) {
			const filesArray = Array.from(e.dataTransfer.files);
			setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
		}
	};

	const handleRemoveFile = (index: number) => {
		setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	};

	const handleSelectFiles = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const parseCSVFile = (file: File): Promise<any> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (event) => {
				try {
					if (!event.target || !event.target.result) {
						reject(new Error('Failed to read file'));
						return;
					}

					const csvContent = event.target.result as string;
					const lines = csvContent.split('\n');

					// Extract headers
					const headers = lines[0].split(',').map((header) => header.trim());

					// Find column indices
					const categoryIndex = headers.findIndex((h) => h.toLowerCase() === 'category');
					const descriptionIndex = headers.findIndex((h) => h.toLowerCase() === 'description');
					const frequencyIndex = headers.findIndex((h) => h.toLowerCase() === 'frequency');
					const severityIndex = headers.findIndex((h) => h.toLowerCase() === 'severity');
					const subcategoryIndex = headers.findIndex((h) => h.toLowerCase() === 'subcategory');
					const subcategoryDescIndex = headers.findIndex(
						(h) => h.toLowerCase() === 'subcategory description'
					);
					const subcategoryFreqIndex = headers.findIndex(
						(h) => h.toLowerCase() === 'subcategory frequency'
					);
					const subcategorySevIndex = headers.findIndex(
						(h) => h.toLowerCase() === 'subcategory severity'
					);
					const tweetIdsIndex = headers.findIndex((h) => h.toLowerCase() === 'tweet ids');

					// Process data rows
					const categories: { [key: string]: Category } = {};

					for (let i = 1; i < lines.length; i++) {
						if (!lines[i].trim()) continue;

						// Handle quoted values with commas inside
						const values: string[] = [];
						let inQuote = false;
						let currentValue = '';

						for (let j = 0; j < lines[i].length; j++) {
							const char = lines[i][j];

							if (char === '"' && (j === 0 || lines[i][j - 1] !== '\\')) {
								inQuote = !inQuote;
							} else if (char === ',' && !inQuote) {
								values.push(currentValue.trim());
								currentValue = '';
							} else {
								currentValue += char;
							}
						}

						// Add the last value
						values.push(currentValue.trim());

						// Extract values
						const categoryName = values[categoryIndex];
						const description = values[descriptionIndex];
						const frequency = parseInt(values[frequencyIndex]) || 0;
						const severity = parseInt(values[severityIndex]) || 0;
						const subcategoryName = values[subcategoryIndex];
						const subcategoryDesc = values[subcategoryDescIndex];
						const subcategoryFreq = parseInt(values[subcategoryFreqIndex]) || 0;
						const subcategorySev = parseInt(values[subcategorySevIndex]) || 0;
						const tweetIds = values[tweetIdsIndex]
							? values[tweetIdsIndex].split(',').map((id) => id.trim())
							: [];

						// Create or update category
						if (!categories[categoryName]) {
							categories[categoryName] = {
								name: categoryName,
								description: description,
								frequency: frequency,
								severity: severity,
								subcategories: []
							};
						}

						// Add subcategory if it exists
						if (subcategoryName) {
							categories[categoryName].subcategories.push({
								name: subcategoryName,
								description: subcategoryDesc,
								frequency: subcategoryFreq,
								severity: subcategorySev,
								tweetIds: tweetIds
							});
						}
					}

					// Convert to array
					const categoriesArray = Object.values(categories);

					// Create company data
					const companyData: CompanyData = {
						name: file.name.replace(/\.[^/.]+$/, ''), // Remove file extension
						fileName: file.name,
						categories: categoriesArray
					};

					resolve(companyData);
				} catch (error) {
					reject(error);
				}
			};

			reader.onerror = () => {
				reject(new Error('Error reading file'));
			};

			reader.readAsText(file);
		});
	};

	const handleAnalyze = async () => {
		if (selectedFiles.length === 0) return;

		setIsAnalyzing(true);
		setProgress(0);

		try {
			const totalFiles = selectedFiles.length;
			const results: CompanyData[] = [];

			for (let i = 0; i < totalFiles; i++) {
				const result = await parseCSVFile(selectedFiles[i]);
				results.push(result);
				setProgress(Math.round(((i + 1) / totalFiles) * 100));
			}

			setParsedData(results);
			setAnalysisComplete(true);
		} catch (error) {
			console.error('Error analyzing files:', error);
		} finally {
			setIsAnalyzing(false);
		}
	};

	const handleExportMetaAnalysis = () => {
		if (!parsedData.length) return;

		// Create CSV content
		let csvContent =
			'Company,Category,Description,Frequency,Severity,Subcategory,Subcategory Description,Subcategory Frequency,Subcategory Severity,Tweet IDs\n';

		parsedData.forEach((company) => {
			company.categories.forEach((category) => {
				if (category.subcategories.length === 0) {
					// Category with no subcategories
					csvContent += `${escapeCSV(company.name)},${escapeCSV(category.name)},${escapeCSV(
						category.description
					)},${category.frequency},${category.severity},,,,,""\n`;
				} else {
					// Categories with subcategories
					category.subcategories.forEach((subcategory) => {
						const tweetIdsStr = subcategory.tweetIds ? subcategory.tweetIds.join(', ') : '';
						csvContent += `${escapeCSV(company.name)},${escapeCSV(category.name)},${escapeCSV(
							category.description
						)},${category.frequency},${category.severity},${escapeCSV(
							subcategory.name
						)},${escapeCSV(subcategory.description)},${subcategory.frequency},${
							subcategory.severity
						},"${tweetIdsStr}"\n`;
					});
				}
			});
		});

		// Create and download the file
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute('download', 'meta_analysis_export.csv');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const escapeCSV = (value: string) => {
		if (!value) return '';
		const stringValue = String(value);
		// If the value contains a comma, newline, or double quote, enclose it in double quotes
		if (stringValue.includes(',') || stringValue.includes('\n') || stringValue.includes('"')) {
			// Replace any double quotes with two double quotes
			return `"${stringValue.replace(/"/g, '""')}"`;
		}
		return stringValue;
	};

	const handleDownloadTemplate = () => {
		// Create CSV headers
		const headers = [
			'Category',
			'Description',
			'Frequency',
			'Severity',
			'Subcategory',
			'Subcategory Description',
			'Subcategory Frequency',
			'Subcategory Severity',
			'Tweet IDs'
		];

		// Create example rows
		const exampleRows = [
			[
				'User Interface',
				'Users are experiencing difficulties with the navigation and layout',
				'8',
				'4',
				'Navigation Issues',
				'Users find it difficult to navigate between sections',
				'5',
				'4',
				'1234567890, 1234567891'
			],
			[
				'User Interface',
				'Users are experiencing difficulties with the navigation and layout',
				'8',
				'4',
				'Layout Problems',
				'The layout is confusing on mobile devices',
				'3',
				'3',
				'1234567892, 1234567893'
			],
			[
				'Performance',
				'Users report slow loading times and crashes',
				'6',
				'5',
				'Slow Loading',
				'Pages take too long to load',
				'4',
				'4',
				'1234567894, 1234567895'
			],
			[
				'Performance',
				'Users report slow loading times and crashes',
				'6',
				'5',
				'App Crashes',
				'The application crashes during specific actions',
				'2',
				'5',
				'1234567896, 1234567897'
			]
		];

		// Combine headers and rows
		let csvContent = headers.join(',') + '\n';
		exampleRows.forEach((row) => {
			csvContent += row.map((value) => escapeCSV(value)).join(',') + '\n';
		});

		// Create and download the file
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute('download', 'pain_points_template.csv');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const renderFileList = () => {
		return (
			<div className="mt-4">
				<h3 className="text-lg font-medium text-gray-800 dark:text-white mb-2">Selected Files</h3>
				{selectedFiles.length === 0 ? (
					<p className="text-gray-500 dark:text-gray-400">No files selected</p>
				) : (
					<ul className="space-y-2">
						{selectedFiles.map((file, index) => (
							<li
								key={index}
								className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-800 rounded-md"
							>
								<span className="text-gray-700 dark:text-gray-300 truncate max-w-xs">
									{file.name}
								</span>
								<button
									onClick={() => handleRemoveFile(index)}
									className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-5 w-5"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</button>
							</li>
						))}
					</ul>
				)}
			</div>
		);
	};

	const renderAnalysisResults = () => {
		return (
			<div>
				{/* Tab navigation */}
				<div className="mb-6 border-b border-gray-200 dark:border-gray-700">
					<ul className="flex flex-wrap -mb-px">
						<li className="mr-2">
							<button
								onClick={() => setActiveTab('overview')}
								className={`inline-block py-4 px-4 text-sm font-medium ${
									activeTab === 'overview'
										? 'text-primary border-b-2 border-primary'
										: 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
								}`}
							>
								Overview
							</button>
						</li>
						<li className="mr-2">
							<button
								onClick={() => setActiveTab('comparison')}
								className={`inline-block py-4 px-4 text-sm font-medium ${
									activeTab === 'comparison'
										? 'text-primary border-b-2 border-primary'
										: 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
								}`}
							>
								Company Comparison
							</button>
						</li>
						<li>
							<button
								onClick={() => setActiveTab('trends')}
								className={`inline-block py-4 px-4 text-sm font-medium ${
									activeTab === 'trends'
										? 'text-primary border-b-2 border-primary'
										: 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
								}`}
							>
								Industry Trends
							</button>
						</li>
					</ul>
				</div>

				{/* Tab content */}
				{activeTab === 'overview' && (
					<Card className="mb-6">
						<CardBody>
							<CommonPainPointsChart data={parsedData} darkMode={darkMode} />
						</CardBody>
					</Card>
				)}

				{activeTab === 'comparison' && (
					<Card className="mb-6">
						<CardBody>
							<CompanyComparisonChart data={parsedData} darkMode={darkMode} />
						</CardBody>
					</Card>
				)}

				{activeTab === 'trends' && (
					<Card>
						<CardBody>
							<IndustryTrendsChart data={parsedData} darkMode={darkMode} />
						</CardBody>
					</Card>
				)}

				{/* Export button */}
				<div className="flex justify-end mt-4">
					<Button variant="primary" onClick={handleExportMetaAnalysis}>
						Export Meta Analysis
					</Button>
				</div>
			</div>
		);
	};

	return (
		<div className="container mx-auto px-4 py-8">
			<h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
				Pain Points Meta Analysis
			</h1>

			{!analysisComplete ? (
				<Card>
					<CardBody>
						<div
							className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-8 text-center"
							onDragOver={handleDragOver}
							onDrop={handleDrop}
						>
							<svg
								className="mx-auto h-12 w-12 text-gray-400"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
								/>
							</svg>
							<p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
								Drag and drop CSV files here, or{' '}
								<button
									className="text-primary hover:text-primary-dark"
									onClick={handleSelectFiles}
								>
									browse
								</button>
							</p>
							<p className="mt-1 text-xs text-gray-500 dark:text-gray-500">
								CSV files with pain point data
							</p>
							<input
								type="file"
								ref={fileInputRef}
								onChange={handleFileChange}
								className="hidden"
								accept=".csv"
								multiple
							/>
						</div>
						<div className="mt-4 flex flex-col sm:flex-row justify-center gap-2">
							<Button variant="primary" onClick={handleSelectFiles}>
								Select Files
							</Button>
							<Button variant="secondary" onClick={handleDownloadTemplate}>
								Download Template
							</Button>
						</div>

						{renderFileList()}

						<div className="mt-6">
							<Button
								variant="primary"
								onClick={handleAnalyze}
								disabled={selectedFiles.length === 0 || isAnalyzing}
								className={isAnalyzing ? 'opacity-50 cursor-not-allowed' : ''}
							>
								{isAnalyzing ? 'Analyzing...' : 'Analyze Files'}
							</Button>
						</div>

						{isAnalyzing && (
							<div className="mt-4">
								<div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5 mb-2">
									<div
										className="bg-primary h-2.5 rounded-full transition-all duration-300"
										style={{ width: `${progress}%` }}
									></div>
								</div>
								<p className="text-sm text-gray-600 dark:text-gray-400 text-center">
									Processing files: {progress}% complete
								</p>
							</div>
						)}
					</CardBody>
				</Card>
			) : (
				renderAnalysisResults()
			)}
		</div>
	);
};

export default MetaAnalysisPage;
