import React from 'react';

interface ProgressBarProps {
	progress: number;
	color?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
	progress,
	color = 'bg-blue-600 dark:bg-blue-500'
}) => {
	// Ensure progress is between 0 and 100
	const normalizedProgress = Math.min(Math.max(progress, 0), 100);

	return (
		<div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5 overflow-hidden">
			<div
				className={`h-2.5 rounded-full ${color} transition-all duration-300 ease-in-out`}
				style={{ width: `${normalizedProgress}%` }}
			/>
		</div>
	);
};

export default ProgressBar;
