import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { AuthCard, AuthHeader, AuthBody, Input, Button, Alert, Link } from '../ui/AuthUI';

interface SignUpProps {
	onSignIn: () => void;
}

const SignUp: React.FC<SignUpProps> = ({ onSignIn }) => {
	// Form state
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	// Validation state
	const [errors, setErrors] = useState<{
		name?: string;
		email?: string;
		password?: string;
		confirmPassword?: string;
	}>({});

	// Form submission state
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState<string | null>(null);
	const [formSuccess, setFormSuccess] = useState<string | null>(null);

	// Auth context
	const { signUp } = useAuth();

	// Validate form
	const validateForm = (): boolean => {
		const newErrors: {
			name?: string;
			email?: string;
			password?: string;
			confirmPassword?: string;
		} = {};

		// Validate name
		if (!name.trim()) {
			newErrors.name = 'Name is required';
		}

		// Validate email
		if (!email.trim()) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			newErrors.email = 'Email is invalid';
		}

		// Validate password
		if (!password) {
			newErrors.password = 'Password is required';
		} else if (password.length < 6) {
			newErrors.password = 'Password must be at least 6 characters';
		}

		// Validate confirm password
		if (password !== confirmPassword) {
			newErrors.confirmPassword = 'Passwords do not match';
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	// Handle form submission
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		// Reset form status
		setFormError(null);
		setFormSuccess(null);

		// Validate form
		if (!validateForm()) {
			return;
		}

		// Submit form
		setIsLoading(true);

		try {
			const { success, error } = await signUp(email, password, name);

			if (success) {
				setFormSuccess('Registration successful! Please check your email to verify your account.');
				// Reset form
				setName('');
				setEmail('');
				setPassword('');
				setConfirmPassword('');
			} else {
				setFormError(error.message || 'Failed to sign up. Please try again.');
			}
		} catch (error) {
			setFormError('An unexpected error occurred. Please try again.');
			console.error('Sign up error:', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<AuthCard>
			<AuthHeader title="Create an Account" subtitle="Sign up to get started with Xpose" />
			<AuthBody>
				{formError && (
					<Alert type="error" message={formError} onDismiss={() => setFormError(null)} />
				)}

				{formSuccess && (
					<Alert type="success" message={formSuccess} onDismiss={() => setFormSuccess(null)} />
				)}

				<form onSubmit={handleSubmit}>
					<Input
						id="name"
						label="Full Name"
						type="text"
						placeholder="John Doe"
						value={name}
						onChange={(e) => setName(e.target.value)}
						error={errors.name}
						required
					/>

					<Input
						id="email"
						label="Email Address"
						type="email"
						placeholder="john@example.com"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						error={errors.email}
						required
					/>

					<Input
						id="password"
						label="Password"
						type="password"
						placeholder="••••••••"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						error={errors.password}
						required
					/>

					<Input
						id="confirmPassword"
						label="Confirm Password"
						type="password"
						placeholder="••••••••"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						error={errors.confirmPassword}
						required
					/>

					<div className="mt-6">
						<Button type="submit" isLoading={isLoading}>
							Create Account
						</Button>
					</div>
				</form>

				<div className="mt-4 text-center">
					<p className="text-sm text-gray-600 dark:text-gray-400">
						Already have an account?{' '}
						<Link onClick={onSignIn} href="#" variant="primary">
							Sign in
						</Link>
					</p>
				</div>
			</AuthBody>
		</AuthCard>
	);
};

export default SignUp;
