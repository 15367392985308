import React, { useState } from 'react';
import { TweetData } from '../pages/dashboard/DashboardPage';
import TweetCard from './TweetCard';
import * as XLSX from 'xlsx';

interface ResultsListProps {
	tweets: TweetData[];
	analysisData?: any; // Optional analysis data for export
	profileHandle?: string;
}

const ResultsList: React.FC<ResultsListProps> = ({
	tweets,
	analysisData,
	profileHandle = 'profile'
}) => {
	const [sortBy, setSortBy] = useState<'timestamp' | 'likes' | 'retweets' | 'replies'>('timestamp');
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
	const [currentPage, setCurrentPage] = useState(1);
	const [sentimentFilter, setSentimentFilter] = useState<'all' | 'positive' | 'negative'>('all');
	const tweetsPerPage = 10;

	// Filter tweets based on sentiment
	const filteredTweets = tweets.filter((tweet) => {
		if (sentimentFilter === 'all') return true;
		if (sentimentFilter === 'positive') return tweet.sentiment === 'positive';
		if (sentimentFilter === 'negative') return tweet.sentiment === 'negative';
		return true;
	});

	// Sort tweets based on current sort settings
	const sortedTweets = [...filteredTweets].sort((a, b) => {
		const aValue = a[sortBy];
		const bValue = b[sortBy];

		if (sortBy === 'timestamp') {
			const aDate = new Date(aValue as string).getTime();
			const bDate = new Date(bValue as string).getTime();

			return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
		}

		if (typeof aValue === 'number' && typeof bValue === 'number') {
			return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
		}

		return 0;
	});

	// Get current tweets for pagination
	const indexOfLastTweet = currentPage * tweetsPerPage;
	const indexOfFirstTweet = indexOfLastTweet - tweetsPerPage;
	const currentTweets = sortedTweets.slice(indexOfFirstTweet, indexOfLastTweet);
	const totalPages = Math.ceil(sortedTweets.length / tweetsPerPage);

	// Toggle sort order between ascending and descending
	const toggleSortOrder = () => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	};

	// Change the sort field
	const changeSortBy = (newSortBy: 'timestamp' | 'likes' | 'retweets' | 'replies') => {
		if (sortBy === newSortBy) {
			toggleSortOrder();
		} else {
			setSortBy(newSortBy);
			setSortOrder('desc'); // Default to descending when changing sort field
		}
		setCurrentPage(1); // Reset to first page when sorting changes
	};

	// Change sentiment filter
	const changeSentimentFilter = (sentiment: 'all' | 'positive' | 'negative') => {
		setSentimentFilter(sentiment);
		setCurrentPage(1); // Reset to first page when filter changes
	};

	// Change page
	const paginate = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		// Scroll to top of results
		window.scrollTo({
			top: document.getElementById('results-list')?.offsetTop || 0,
			behavior: 'smooth'
		});
	};

	// Go to next page
	const nextPage = () => {
		if (currentPage < totalPages) {
			paginate(currentPage + 1);
		}
	};

	// Go to previous page
	const prevPage = () => {
		if (currentPage > 1) {
			paginate(currentPage - 1);
		}
	};

	// Export tweets to CSV
	const exportToCSV = () => {
		// Use the provided profile handle instead of extracting from tweets

		// Determine sentiment type for filename
		const sentimentType =
			sentimentFilter === 'all'
				? 'unclassified'
				: sentimentFilter === 'positive'
				? 'positive'
				: 'negative';

		// Prepare CSV content
		const headers = [
			'ID',
			'Author',
			'Handle',
			'Content',
			'Timestamp',
			'Likes',
			'Retweets',
			'Replies',
			'URL',
			'Mentioned User',
			'Sentiment'
		];
		const csvContent = [
			headers.join(','),
			...sortedTweets.map((tweet) => {
				// Escape content to handle commas and quotes
				const escapedContent = tweet.content.replace(/"/g, '""');

				return [
					tweet.id,
					tweet.author.replace(/,/g, ' '),
					tweet.handle,
					`"${escapedContent}"`,
					tweet.timestamp,
					tweet.likes,
					tweet.retweets,
					tweet.replies,
					tweet.tweet_url,
					tweet.mentioned_user || '',
					tweet.sentiment || 'unclassified'
				].join(',');
			})
		].join('\n');

		// Create and download the file
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute(
			'download',
			`${profileHandle}_${sentimentType}_scraped_tweets_${new Date()
				.toISOString()
				.slice(0, 10)}.csv`
		);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	// Export tweets to Excel (XLSX)
	const exportToExcel = async () => {
		// Use the provided profile handle instead of extracting from tweets

		// Determine sentiment type for filename
		const sentimentType =
			sentimentFilter === 'all'
				? 'unclassified'
				: sentimentFilter === 'positive'
				? 'positive'
				: 'negative';

		try {
			// Prepare worksheet data
			const wsData = sortedTweets.map((tweet) => ({
				ID: tweet.id,
				Author: tweet.author,
				Handle: tweet.handle,
				Content: tweet.content,
				Timestamp: tweet.timestamp,
				Likes: tweet.likes,
				Retweets: tweet.retweets,
				Replies: tweet.replies,
				URL: tweet.tweet_url,
				'Mentioned User': tweet.mentioned_user || '',
				Sentiment: tweet.sentiment || 'unclassified'
			}));

			// Create worksheet and workbook
			const ws = XLSX.utils.json_to_sheet(wsData);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Tweets');

			// Generate and download the file
			XLSX.writeFile(
				wb,
				`${profileHandle}_${sentimentType}_scraped_tweets_${new Date()
					.toISOString()
					.slice(0, 10)}.xlsx`
			);
		} catch (error) {
			console.error('Error exporting to Excel:', error);
			alert('Failed to export to Excel. Please try CSV format instead.');
		}
	};

	// Count tweets by sentiment
	const positiveCount = tweets.filter((t) => t.sentiment === 'positive').length;
	const negativeCount = tweets.filter((t) => t.sentiment === 'negative').length;
	const unclassifiedCount = tweets.filter((t) => !t.sentiment).length;

	return (
		<div className="space-y-6" id="results-list">
			<div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
				<h2 className="text-xl font-bold text-gray-800 dark:text-white">
					Results ({tweets.length} tweets)
				</h2>
				<div className="flex flex-wrap gap-2">
					<button
						onClick={() => changeSortBy('timestamp')}
						className={`px-3 py-1 text-sm rounded-md ${
							sortBy === 'timestamp'
								? 'bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200'
								: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
						}`}
					>
						Date {sortBy === 'timestamp' && (sortOrder === 'asc' ? '↑' : '↓')}
					</button>
					<button
						onClick={() => changeSortBy('likes')}
						className={`px-3 py-1 text-sm rounded-md ${
							sortBy === 'likes'
								? 'bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200'
								: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
						}`}
					>
						Likes {sortBy === 'likes' && (sortOrder === 'asc' ? '↑' : '↓')}
					</button>
					<button
						onClick={() => changeSortBy('retweets')}
						className={`px-3 py-1 text-sm rounded-md ${
							sortBy === 'retweets'
								? 'bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200'
								: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
						}`}
					>
						Retweets {sortBy === 'retweets' && (sortOrder === 'asc' ? '↑' : '↓')}
					</button>
					<button
						onClick={() => changeSortBy('replies')}
						className={`px-3 py-1 text-sm rounded-md ${
							sortBy === 'replies'
								? 'bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200'
								: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
						}`}
					>
						Replies {sortBy === 'replies' && (sortOrder === 'asc' ? '↑' : '↓')}
					</button>
				</div>
			</div>

			{/* Sentiment filter */}
			{(positiveCount > 0 || negativeCount > 0) && (
				<div className="flex flex-wrap gap-2">
					<button
						onClick={() => changeSentimentFilter('all')}
						className={`px-3 py-1 text-sm rounded-md ${
							sentimentFilter === 'all'
								? 'bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200'
								: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
						}`}
					>
						All Tweets ({tweets.length})
					</button>
					{positiveCount > 0 && (
						<button
							onClick={() => changeSentimentFilter('positive')}
							className={`px-3 py-1 text-sm rounded-md ${
								sentimentFilter === 'positive'
									? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
									: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
							}`}
						>
							Positive ({positiveCount})
						</button>
					)}
					{negativeCount > 0 && (
						<button
							onClick={() => changeSentimentFilter('negative')}
							className={`px-3 py-1 text-sm rounded-md ${
								sentimentFilter === 'negative'
									? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
									: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
							}`}
						>
							Negative ({negativeCount})
						</button>
					)}
					{unclassifiedCount > 0 && (
						<span className="px-3 py-1 text-sm rounded-md bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200">
							Unclassified ({unclassifiedCount})
						</span>
					)}
				</div>
			)}

			{sortedTweets.length > 0 ? (
				<>
					{/* Export buttons */}
					<div className="flex justify-end gap-2 mb-4">
						<button
							onClick={exportToCSV}
							className="px-4 py-2 text-sm font-medium rounded-md bg-green-600 text-white hover:bg-green-700 dark:bg-green-500 dark:hover:bg-green-600 transition-colors flex items-center"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-4 w-4 mr-2"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
								/>
							</svg>
							Export CSV
						</button>
						<button
							onClick={exportToExcel}
							className="px-4 py-2 text-sm font-medium rounded-md bg-blue-600 text-white hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 transition-colors flex items-center"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-4 w-4 mr-2"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
								/>
							</svg>
							Export Excel
						</button>
					</div>

					<div className="space-y-4">
						{currentTweets.map((tweet) => (
							<TweetCard key={tweet.id} tweet={tweet} />
						))}
					</div>

					{/* Pagination */}
					{totalPages > 1 && (
						<div className="flex justify-center mt-8">
							<nav className="flex items-center gap-1">
								<button
									onClick={prevPage}
									disabled={currentPage === 1}
									className={`p-2 rounded-md ${
										currentPage === 1
											? 'text-gray-400 cursor-not-allowed'
											: 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700'
									}`}
								>
									<svg
										className="w-5 h-5"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M15 19l-7-7 7-7"
										/>
									</svg>
								</button>

								<div className="flex items-center gap-1">
									{Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => {
										// Show limited page numbers with ellipsis for better UX
										if (
											number === 1 ||
											number === totalPages ||
											(number >= currentPage - 1 && number <= currentPage + 1)
										) {
											return (
												<button
													key={number}
													onClick={() => paginate(number)}
													className={`w-8 h-8 flex items-center justify-center rounded-md ${
														currentPage === number
															? 'bg-indigo-600 text-white dark:bg-indigo-500'
															: 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700'
													}`}
												>
													{number}
												</button>
											);
										} else if (
											(number === currentPage - 2 && currentPage > 3) ||
											(number === currentPage + 2 && currentPage < totalPages - 2)
										) {
											return (
												<span
													key={number}
													className="w-8 h-8 flex items-center justify-center text-gray-500 dark:text-gray-400"
												>
													...
												</span>
											);
										}
										return null;
									})}
								</div>

								<button
									onClick={nextPage}
									disabled={currentPage === totalPages}
									className={`p-2 rounded-md ${
										currentPage === totalPages
											? 'text-gray-400 cursor-not-allowed'
											: 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700'
									}`}
								>
									<svg
										className="w-5 h-5"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M9 5l7 7-7 7"
										/>
									</svg>
								</button>
							</nav>
						</div>
					)}
				</>
			) : (
				<div className="text-center py-10">
					<p className="text-gray-500 dark:text-gray-400">No tweets found</p>
				</div>
			)}
		</div>
	);
};

export default ResultsList;
