import { AnalysisResponse } from '../types/analysisTypes';
import { TweetData } from '../pages/dashboard/DashboardPage';
import * as XLSX from 'xlsx';
// Import types for jspdf and jspdf-autotable
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

/**
 * Utility functions for exporting data in various formats
 */

/**
 * Extract handle from profile URL
 * @param profileUrl The profile URL
 * @returns The handle without @ symbol
 */
const extractHandleFromUrl = (profileUrl: string): string => {
	if (!profileUrl) return 'profile';

	const usernameMatch = profileUrl.match(/twitter\.com\/([^/]+)|x\.com\/([^/]+)/);
	const handle = usernameMatch ? usernameMatch[1] || usernameMatch[2] : 'profile';

	return handle.toLowerCase();
};

/**
 * Export analysis data to Excel (XLSX) format
 * @param analysisData The analysis data to export
 * @param tweets The tweets data associated with the analysis
 * @param profileHandle The handle of the profile that was scraped
 */
export const exportAnalysisToExcel = (
	analysisData: AnalysisResponse,
	tweets: TweetData[],
	profileHandle: string = 'profile'
) => {
	// Use the provided profile handle instead of extracting from tweets
	profileHandle = profileHandle || 'profile';

	const wb = XLSX.utils.book_new();

	// Create summary sheet
	const summaryData = [
		['Xpose Analysis Report', ''],
		['Date', new Date().toLocaleDateString()],
		['Total Tweets Analyzed', analysisData.pain_points_analysis.total_analyzed_tweets.toString()],
		['Total Pain Point Categories', analysisData.pain_points_analysis.categories.length.toString()],
		['', ''],
		['Category', 'Frequency', 'Severity (1-5)', 'Subcategories']
	];

	// Add categories to summary
	analysisData.pain_points_analysis.categories.forEach((category) => {
		summaryData.push([
			category.category,
			category.frequency.toString(),
			category.severity.toString(),
			category.subcategories.length.toString()
		]);
	});

	const summaryWs = XLSX.utils.aoa_to_sheet(summaryData);
	XLSX.utils.book_append_sheet(wb, summaryWs, 'Summary');

	// Create detailed categories sheet
	const categoriesData = [
		[
			'Category',
			'Description',
			'Frequency',
			'Severity',
			'Subcategory',
			'Subcategory Description',
			'Subcategory Frequency',
			'Subcategory Severity',
			'Tweet IDs'
		]
	];

	// Add detailed category data
	analysisData.pain_points_analysis.categories.forEach((category) => {
		category.subcategories.forEach((subcategory) => {
			categoriesData.push([
				category.category,
				category.description,
				category.frequency.toString(),
				category.severity.toString(),
				subcategory.name,
				subcategory.description,
				subcategory.frequency.toString(),
				subcategory.severity.toString(),
				subcategory.tweet_ids.join(', ')
			]);
		});
	});

	const categoriesWs = XLSX.utils.aoa_to_sheet(categoriesData);
	XLSX.utils.book_append_sheet(wb, categoriesWs, 'Categories Detail');

	// Create tweets sheet with referenced tweets
	const tweetsData = [
		[
			'Tweet ID',
			'Author',
			'Handle',
			'Content',
			'Timestamp',
			'Likes',
			'Retweets',
			'Replies',
			'URL',
			'Mentioned User'
		]
	];

	// Get all tweet IDs referenced in the analysis
	const referencedTweetIds = new Set<string>();
	analysisData.pain_points_analysis.categories.forEach((category) => {
		category.subcategories.forEach((subcategory) => {
			subcategory.tweet_ids.forEach((id: string) => referencedTweetIds.add(id));
		});
	});

	// Add referenced tweets
	tweets
		.filter((tweet) => referencedTweetIds.has(tweet.id))
		.forEach((tweet) => {
			tweetsData.push([
				tweet.id,
				tweet.author,
				tweet.handle,
				tweet.content,
				tweet.timestamp,
				tweet.likes.toString(),
				tweet.retweets.toString(),
				tweet.replies.toString(),
				tweet.tweet_url,
				tweet.mentioned_user || ''
			]);
		});

	const tweetsWs = XLSX.utils.aoa_to_sheet(tweetsData);
	XLSX.utils.book_append_sheet(wb, tweetsWs, 'Referenced Tweets');

	// Generate and download the file
	XLSX.writeFile(
		wb,
		`${profileHandle}_pain_point_analysis_${new Date().toISOString().slice(0, 10)}.xlsx`
	);
};

/**
 * Export analysis data to CSV format
 * @param analysisData The analysis data to export
 * @param profileHandle The handle of the profile that was scraped
 */
export const exportAnalysisToCSV = (
	analysisData: AnalysisResponse,
	profileHandle: string = 'profile'
) => {
	// Prepare CSV content for categories and subcategories
	const headers = [
		'Category',
		'Description',
		'Frequency',
		'Severity',
		'Subcategory',
		'Subcategory Description',
		'Subcategory Frequency',
		'Subcategory Severity',
		'Tweet IDs'
	];

	const rows: string[] = [];

	// Use the provided profile handle or default to 'profile'
	profileHandle = profileHandle || 'profile';

	analysisData.pain_points_analysis.categories.forEach((category) => {
		category.subcategories.forEach((subcategory) => {
			// Escape content to handle commas and quotes
			const categoryDesc = category.description.replace(/"/g, '""');
			const subcategoryDesc = subcategory.description.replace(/"/g, '""');

			rows.push(
				[
					`"${category.category}"`,
					`"${categoryDesc}"`,
					category.frequency,
					category.severity,
					`"${subcategory.name}"`,
					`"${subcategoryDesc}"`,
					subcategory.frequency,
					subcategory.severity,
					`"${subcategory.tweet_ids.join(', ')}"`
				].join(',')
			);
		});
	});

	const csvContent = [headers.join(','), ...rows].join('\n');

	// Create and download the file
	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.setAttribute('href', url);
	link.setAttribute(
		'download',
		`${profileHandle}_pain_point_analysis_${new Date().toISOString().slice(0, 10)}.csv`
	);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

/**
 * Export analysis data to PDF format
 * @param analysisData The analysis data to export
 * @param tweets The tweets data associated with the analysis
 * @param profileHandle The handle of the profile that was scraped
 */
export const exportAnalysisToPDF = (
	analysisData: AnalysisResponse,
	tweets: TweetData[],
	profileHandle: string = 'profile'
) => {
	// Use the provided profile handle instead of extracting from tweets
	profileHandle = profileHandle || 'profile';

	try {
		// Create a new PDF document
		const doc = new jsPDF();

		// Add title
		doc.setFontSize(18);
		doc.text('Xpose Analysis Report', 14, 22);

		// Add report metadata
		doc.setFontSize(10);
		doc.text(`Date: ${new Date().toLocaleDateString()}`, 14, 30);
		doc.text(
			`Total Tweets Analyzed: ${analysisData.pain_points_analysis.total_analyzed_tweets}`,
			14,
			35
		);
		doc.text(
			`Total Pain Point Categories: ${analysisData.pain_points_analysis.categories.length}`,
			14,
			40
		);

		// Add categories summary table
		doc.setFontSize(14);
		doc.text('Categories Summary', 14, 50);

		const categoriesTableData = analysisData.pain_points_analysis.categories.map((category) => [
			category.category,
			category.description.substring(0, 50) + (category.description.length > 50 ? '...' : ''),
			category.frequency.toString(),
			category.severity.toString(),
			category.subcategories.length.toString()
		]);

		(doc as any).autoTable({
			startY: 55,
			head: [['Category', 'Description', 'Frequency', 'Severity', 'Subcategories']],
			body: categoriesTableData,
			theme: 'grid',
			headStyles: { fillColor: [75, 75, 250] }
		});

		// Add subcategories table
		doc.addPage();
		doc.setFontSize(14);
		doc.text('Subcategories Detail', 14, 22);

		const subcategoriesTableData: any[] = [];
		analysisData.pain_points_analysis.categories.forEach((category) => {
			category.subcategories.forEach((subcategory) => {
				subcategoriesTableData.push([
					category.category,
					subcategory.name,
					subcategory.description.substring(0, 40) +
						(subcategory.description.length > 40 ? '...' : ''),
					subcategory.frequency.toString(),
					subcategory.severity.toString(),
					subcategory.tweet_ids.length.toString()
				]);
			});
		});

		(doc as any).autoTable({
			startY: 27,
			head: [['Category', 'Subcategory', 'Description', 'Frequency', 'Severity', 'Tweet Count']],
			body: subcategoriesTableData,
			theme: 'grid',
			headStyles: { fillColor: [75, 75, 250] }
		});

		// Add related tweets
		doc.addPage();
		doc.setFontSize(14);
		doc.text('Related Tweets', 14, 22);

		// Get all tweet IDs referenced in the analysis
		const referencedTweetIds = new Set<string>();
		analysisData.pain_points_analysis.categories.forEach((category) => {
			category.subcategories.forEach((subcategory) => {
				// Only include up to 10 tweets per subcategory to keep PDF size reasonable
				const limitedTweetIds = subcategory.tweet_ids.slice(0, 10);
				limitedTweetIds.forEach((id: string) => referencedTweetIds.add(id));
			});
		});

		// Filter tweets and prepare data
		const tweetTableData = tweets
			.filter((tweet) => referencedTweetIds.has(tweet.id))
			.map((tweet) => [
				tweet.author,
				'@' + tweet.handle,
				tweet.content.substring(0, 80) + (tweet.content.length > 80 ? '...' : ''),
				tweet.timestamp
			]);

		(doc as any).autoTable({
			startY: 27,
			head: [['Author', 'Handle', 'Content', 'Timestamp']],
			body: tweetTableData,
			theme: 'grid',
			headStyles: { fillColor: [75, 75, 250] },
			columnStyles: {
				2: { cellWidth: 80 }
			}
		});

		// Save the PDF
		doc.save(`${profileHandle}_pain_point_analysis_${new Date().toISOString().slice(0, 10)}.pdf`);
	} catch (error) {
		console.error('Error generating PDF:', error);
		throw new Error('Failed to generate PDF. Please check if jsPDF is properly installed.');
	}
};
