import React from 'react';

// Define app pages
export type AppPage = 'scraper' | 'meta-analysis' | 'settings';

interface SidebarProps {
	currentPage: AppPage;
	navigateTo: (page: AppPage) => void;
	darkMode: boolean;
	setDarkMode: (mode: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ currentPage, navigateTo, darkMode, setDarkMode }) => {
	return (
		<div className="h-full flex flex-col py-4">
			<div className="px-4 py-2 border-b border-gray-200 dark:border-gray-700">
				<h2 className="text-xl font-semibold text-gray-800 dark:text-white">Xpose</h2>
				<p className="text-sm text-gray-500 dark:text-gray-400">Social Media Analysis</p>
			</div>
			<nav className="flex-1 px-2 py-4 space-y-1">
				<button
					onClick={() => navigateTo('scraper')}
					className={`w-full flex items-center px-4 py-3 text-left rounded-lg transition-colors ${
						currentPage === 'scraper'
							? 'bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-200'
							: 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
					}`}
				>
					<svg
						className="w-5 h-5 mr-3"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
					Profile Scraper
				</button>
				{/* <button
					onClick={() => navigateTo('meta-analysis')}
					className={`w-full flex items-center px-4 py-3 text-left rounded-lg transition-colors ${
						currentPage === 'meta-analysis'
							? 'bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-200'
							: 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
					}`}
				>
					<svg
						className="w-5 h-5 mr-3"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
						/>
					</svg>
					Meta Analysis
				</button> */}
			</nav>
			{/*<div className="mt-auto px-2 py-2">
				<button
					onClick={() => navigateTo('settings')}
					className={`w-full flex items-center px-4 py-3 text-left rounded-lg transition-colors ${
						currentPage === 'settings'
							? 'bg-indigo-100 dark:bg-indigo-900 text-indigo-700 dark:text-indigo-200'
							: 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
					}`}
				>
					<svg
						className="w-5 h-5 mr-3"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
						/>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
						/>
					</svg>
					Settings
				</button>
			</div>*/}
			<div className="px-4 py-2 border-t border-gray-200 dark:border-gray-700">
				<div className="flex items-center justify-between">
					<span className="text-sm text-gray-500 dark:text-gray-400">Theme</span>
					<button
						onClick={() => setDarkMode(!darkMode)}
						className="p-2 rounded-lg bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
					>
						{darkMode ? (
							<svg
								className="w-5 h-5"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
								/>
							</svg>
						) : (
							<svg
								className="w-5 h-5"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
								/>
							</svg>
						)}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
