import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { AuthCard, AuthHeader, AuthBody, Input, Button, Alert, Link } from '../ui/AuthUI';

interface ForgotPasswordProps {
	onBackToSignIn: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onBackToSignIn }) => {
	// Form state
	const [email, setEmail] = useState('');

	// Validation state
	const [errors, setErrors] = useState<{
		email?: string;
	}>({});

	// Form submission state
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState<string | null>(null);
	const [formSuccess, setFormSuccess] = useState<string | null>(null);

	// Auth context
	const { resetPassword } = useAuth();

	// Validate form
	const validateForm = (): boolean => {
		const newErrors: {
			email?: string;
		} = {};

		// Validate email
		if (!email.trim()) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			newErrors.email = 'Email is invalid';
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	// Handle form submission
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		// Reset form status
		setFormError(null);
		setFormSuccess(null);

		// Validate form
		if (!validateForm()) {
			return;
		}

		// Submit form
		setIsLoading(true);

		try {
			const { success, error } = await resetPassword(email);

			if (success) {
				setFormSuccess('Password reset instructions have been sent to your email.');
				setEmail('');
			} else {
				setFormError(error.message || 'Failed to send reset instructions. Please try again.');
			}
		} catch (error) {
			setFormError('An unexpected error occurred. Please try again.');
			console.error('Reset password error:', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<AuthCard>
			<AuthHeader
				title="Reset Password"
				subtitle="Enter your email to receive reset instructions"
			/>
			<AuthBody>
				{formError && (
					<Alert type="error" message={formError} onDismiss={() => setFormError(null)} />
				)}

				{formSuccess && (
					<Alert type="success" message={formSuccess} onDismiss={() => setFormSuccess(null)} />
				)}

				<form onSubmit={handleSubmit}>
					<Input
						id="email"
						label="Email Address"
						type="email"
						placeholder="john@example.com"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						error={errors.email}
						required
					/>

					<div className="mt-6">
						<Button type="submit" isLoading={isLoading}>
							Send Reset Instructions
						</Button>
					</div>
				</form>

				<div className="mt-4 text-center">
					<p className="text-sm text-gray-600 dark:text-gray-400">
						<Link onClick={onBackToSignIn} href="#" variant="secondary">
							Back to Sign In
						</Link>
					</p>
				</div>
			</AuthBody>
		</AuthCard>
	);
};

export default ForgotPassword;
