import React, { useState } from 'react';
import { Card, CardBody, Button } from '../ui/ScraperUI';

// Custom Checkbox component
interface CheckboxProps {
	isSelected?: boolean;
	onChange?: () => void;
	children?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({ isSelected = false, onChange, children }) => {
	return (
		<label className="inline-flex items-center cursor-pointer">
			<div className="relative flex items-center">
				<input type="checkbox" className="sr-only" checked={isSelected} onChange={onChange} />
				<div
					className={`w-5 h-5 border rounded mr-2 flex items-center justify-center ${
						isSelected ? 'bg-primary border-primary' : 'border-gray-300 dark:border-gray-600'
					}`}
				>
					{isSelected && (
						<svg
							className="w-3 h-3 text-white"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M5 13l4 4L19 7"
							></path>
						</svg>
					)}
				</div>
				<span className="text-sm text-gray-700 dark:text-gray-300">{children}</span>
			</div>
		</label>
	);
};

interface Category {
	name: string;
	description: string;
	frequency: number;
	severity: number;
	subcategories: any[];
}

interface CompanyData {
	name: string;
	fileName: string;
	categories: Category[];
}

interface CompanyComparisonChartProps {
	data: CompanyData[];
	darkMode: boolean;
}

const CompanyComparisonChart: React.FC<CompanyComparisonChartProps> = ({ data, darkMode }) => {
	const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
	const [showComparison, setShowComparison] = useState<boolean>(false);

	const handleCompanyToggle = (companyName: string) => {
		setSelectedCompanies((prev) =>
			prev.includes(companyName)
				? prev.filter((name) => name !== companyName)
				: [...prev, companyName]
		);
	};

	const handleCompare = () => {
		if (selectedCompanies.length >= 2) {
			setShowComparison(true);
		}
	};

	const resetComparison = () => {
		setShowComparison(false);
	};

	// Get the selected companies' data
	const selectedCompaniesData = data.filter((company) => selectedCompanies.includes(company.name));

	// Get all unique categories across selected companies
	const allCategories = new Set<string>();
	selectedCompaniesData.forEach((company) => {
		company.categories.forEach((category) => {
			allCategories.add(category.name.toLowerCase().trim());
		});
	});

	// Create comparison data structure
	const comparisonData = Array.from(allCategories)
		.map((categoryName) => {
			const categoryData: {
				name: string;
				companies: {
					[key: string]: {
						frequency: number;
						severity: number;
						exists: boolean;
					};
				};
			} = {
				name: categoryName,
				companies: {}
			};

			// Initialize with all selected companies
			selectedCompaniesData.forEach((company) => {
				categoryData.companies[company.name] = {
					frequency: 0,
					severity: 0,
					exists: false
				};

				// Find if this company has this category
				const foundCategory = company.categories.find(
					(cat) => cat.name.toLowerCase().trim() === categoryName
				);

				if (foundCategory) {
					categoryData.companies[company.name] = {
						frequency: foundCategory.frequency,
						severity: foundCategory.severity,
						exists: true
					};
					// Use the proper case from the found category
					categoryData.name = foundCategory.name;
				}
			});

			return categoryData;
		})
		.sort((a, b) => {
			// Sort by how many companies have this category (descending)
			const aCount = Object.values(a.companies).filter((c) => c.exists).length;
			const bCount = Object.values(b.companies).filter((c) => c.exists).length;
			return bCount - aCount;
		});

	if (!showComparison) {
		return (
			<div>
				<h3 className="text-lg font-medium text-gray-800 dark:text-white mb-4">
					Compare Companies
				</h3>
				<p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
					Select two or more companies to compare their pain points side by side
				</p>

				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 mb-4">
					{data.map((company, index) => (
						<div
							key={index}
							className={`
                p-3 rounded-lg border-2 
                ${
									selectedCompanies.includes(company.name)
										? 'border-blue-500 dark:border-blue-400 bg-blue-50 dark:bg-blue-900/20'
										: 'border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800'
								}
              `}
						>
							<Checkbox
								isSelected={selectedCompanies.includes(company.name)}
								onChange={() => handleCompanyToggle(company.name)}
							>
								<span className="font-medium capitalize">{company.name}</span>
							</Checkbox>
							<div className="mt-2 text-xs text-gray-500 dark:text-gray-400">
								{company.categories.length} categories
							</div>
						</div>
					))}
				</div>

				<div className="flex justify-end">
					<Button variant="primary" disabled={selectedCompanies.length < 2} onClick={handleCompare}>
						Compare Companies
					</Button>
				</div>
			</div>
		);
	}

	return (
		<div>
			<div className="flex justify-between items-center mb-4">
				<h3 className="text-lg font-medium text-gray-800 dark:text-white">Company Comparison</h3>
				<Button size="sm" variant="secondary" onClick={resetComparison}>
					Back to Selection
				</Button>
			</div>

			<div className="mb-4">
				<p className="text-sm text-gray-600 dark:text-gray-400">
					Comparing {selectedCompanies.length} companies: {selectedCompanies.join(', ')}
				</p>
			</div>

			{/* Comparison table */}
			<div className="overflow-x-auto">
				<table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
					<thead className="bg-gray-50 dark:bg-gray-800">
						<tr>
							<th
								scope="col"
								className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
							>
								Pain Point
							</th>
							{selectedCompaniesData.map((company, index) => (
								<th
									key={index}
									scope="col"
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
								>
									{company.name}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-800">
						{comparisonData.map((category, index) => (
							<tr
								key={index}
								className={
									index % 2 === 0 ? 'bg-white dark:bg-gray-900' : 'bg-gray-50 dark:bg-gray-800'
								}
							>
								<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
									{category.name}
								</td>
								{selectedCompaniesData.map((company, companyIndex) => (
									<td
										key={companyIndex}
										className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400"
									>
										{category.companies[company.name].exists ? (
											<div>
												<div className="font-medium">
													Freq: {category.companies[company.name].frequency}
												</div>
												<div>Sev: {category.companies[company.name].severity.toFixed(1)}</div>
											</div>
										) : (
											<span className="text-gray-400 dark:text-gray-600">Not found</span>
										)}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>

			{/* Visual comparison */}
			<div className="mt-8">
				<h4 className="text-md font-medium text-gray-800 dark:text-white mb-4">
					Visual Comparison
				</h4>

				{comparisonData.slice(0, 5).map((category, index) => (
					<div key={index} className="mb-6">
						<h5 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
							{category.name}
						</h5>

						<div className="space-y-3">
							{selectedCompaniesData.map((company, companyIndex) => (
								<div key={companyIndex}>
									<div className="flex items-center">
										<span className="w-24 text-xs text-gray-600 dark:text-gray-400">
											{company.name}
										</span>
										<div className="flex-1">
											{category.companies[company.name].exists ? (
												<div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-4">
													<div
														className="bg-blue-600 h-4 rounded-full flex items-center justify-end pr-2 text-xs text-white"
														style={{
															width: `${Math.min(
																100,
																(category.companies[company.name].frequency / 50) * 100
															)}%`,
															opacity: category.companies[company.name].severity / 5
														}}
													>
														{category.companies[company.name].frequency}
													</div>
												</div>
											) : (
												<div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-4 flex items-center justify-center">
													<span className="text-xs text-gray-500 dark:text-gray-400">
														Not present
													</span>
												</div>
											)}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default CompanyComparisonChart;
