import React, { useState } from 'react';
import Sidebar, { AppPage } from './Sidebar';
import { Header } from './Header';

interface LayoutProps {
	children: React.ReactNode;
	currentPage: AppPage;
	navigateTo: (page: AppPage) => void;
	darkMode: boolean;
	setDarkMode: (mode: boolean) => void;
}

const Layout: React.FC<LayoutProps> = ({
	children,
	currentPage,
	navigateTo,
	darkMode,
	setDarkMode
}) => {
	const [sidebarOpen, setSidebarOpen] = useState(false);

	return (
		<div className={`min-h-screen flex flex-col ${darkMode ? 'dark bg-gray-900' : 'bg-gray-50'}`}>
			<div className="flex flex-1 relative">
				<Header navigateTo={navigateTo} />

				{/* Fixed sidebar for desktop */}
				<div className="hidden md:block md:w-64 fixed inset-y-0 left-0 bg-white dark:bg-gray-800 shadow-lg z-10">
					<Sidebar
						currentPage={currentPage}
						navigateTo={navigateTo}
						darkMode={darkMode}
						setDarkMode={setDarkMode}
					/>
				</div>

				{/* Mobile sidebar */}
				<div
					className={`md:hidden fixed inset-y-0 left-0 top-0 h-screen z-30 w-64 bg-white dark:bg-gray-800 shadow-lg transform transition-transform duration-300 ease-in-out ${
						sidebarOpen ? 'translate-x-0' : '-translate-x-full'
					}`}
				>
					<Sidebar
						currentPage={currentPage}
						navigateTo={(page) => {
							navigateTo(page);
							setSidebarOpen(false);
						}}
						darkMode={darkMode}
						setDarkMode={setDarkMode}
					/>
				</div>

				{/* Overlay for mobile sidebar */}
				{sidebarOpen && (
					<div
						className="fixed inset-0 z-20 bg-black bg-opacity-50 md:hidden"
						onClick={() => setSidebarOpen(false)}
					></div>
				)}

				{/* Main content area with padding for sidebar */}
				<div className="flex-1 md:ml-64 mt-12 overflow-y-auto">
					<div className="p-4 md:p-6">{children}</div>
				</div>
			</div>
		</div>
	);
};

export default Layout;
