import { createClient } from '@supabase/supabase-js';

// Supabase configuration
const supabaseUrl = 'https://swysrpuhsubeqrxnywrr.supabase.co';
const supabaseAnonKey =
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN3eXNycHVoc3ViZXFyeG55d3JyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDA1OTgzNjMsImV4cCI6MjA1NjE3NDM2M30.D5fWRWbsdN25HLIqhRUYp-xHTk46Yz8lmjuFRX00dpk';

// Create a single supabase client for interacting with your database
export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Types for authentication
export type AuthUser = {
	id: string;
	email?: string;
	user_metadata?: {
		name?: string;
	};
	app_metadata?: {
		provider?: string;
	};
	created_at?: string;
};

// User profile type
export type UserProfile = {
	uid: string;
	openai_key?: string | null;
};

// Authentication helper functions
export const signUp = async (email: string, password: string, name: string) => {
	const { data, error } = await supabase.auth.signUp({
		email,
		password,
		options: {
			data: {
				name
			},
			emailRedirectTo: `${window.location.origin}/auth/callback`
		}
	});

	return { data, error };
};

export const signIn = async (email: string, password: string) => {
	const { data, error } = await supabase.auth.signInWithPassword({
		email,
		password
	});

	return { data, error };
};

export const signOut = async () => {
	const { error } = await supabase.auth.signOut();
	return { error };
};

export const resetPassword = async (email: string) => {
	const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
		redirectTo: `${window.location.origin}/auth/reset-password`
	});

	return { data, error };
};

export const updatePassword = async (newPassword: string) => {
	const { data, error } = await supabase.auth.updateUser({
		password: newPassword
	});

	return { data, error };
};

export const getCurrentUser = async () => {
	const {
		data: { user },
		error
	} = await supabase.auth.getUser();
	return { user, error };
};

export const getSession = async () => {
	const {
		data: { session },
		error
	} = await supabase.auth.getSession();
	return { session, error };
};

// Setup auth state change listener
export const setupAuthListener = (callback: (user: AuthUser | null) => void) => {
	return supabase.auth.onAuthStateChange((event, session) => {
		if (event === 'SIGNED_IN' && session?.user) {
			callback(session.user as AuthUser);
		} else if (event === 'SIGNED_OUT') {
			callback(null);
		} else if (event === 'USER_UPDATED' && session?.user) {
			callback(session.user as AuthUser);
		}
	});
};

// Verify email
export const verifyEmail = async (token: string, email: string) => {
	const { data, error } = await supabase.auth.verifyOtp({
		token_hash: token,
		type: 'email',
		email
	});

	return { data, error };
};

// User profile functions
export const getUserProfile = async (userId: string) => {
	const { data, error } = await supabase.from('users').select('*').eq('uid', userId).single();

	return { data, error };
};

export const updateOpenAIKey = async (userId: string, apiKey: string) => {
	const { data, error } = await supabase
		.from('users')
		.update({ openai_key: apiKey })
		.eq('uid', userId)
		.select();

	return { data, error };
};
