import React, { useMemo } from 'react';

interface Category {
	name: string;
	description: string;
	frequency: number;
	severity: number;
	subcategories: any[];
}

interface CompanyData {
	name: string;
	fileName: string;
	categories: Category[];
}

interface AggregatedCategory {
	name: string;
	totalFrequency: number;
	averageSeverity: number;
	companyCount: number;
	percentage: number;
}

interface IndustryTrendsChartProps {
	data: CompanyData[];
	darkMode: boolean;
}

const IndustryTrendsChart: React.FC<IndustryTrendsChartProps> = ({ data, darkMode }) => {
	const industryTrends = useMemo(() => {
		if (!data.length) return [];

		// Map to store aggregated categories
		const categoriesMap = new Map<string, AggregatedCategory>();

		// Calculate total frequency across all companies
		const totalFrequencyAcrossAll = data.reduce((total, company) => {
			return total + company.categories.reduce((sum, category) => sum + category.frequency, 0);
		}, 0);

		// Process each company's data
		data.forEach((company) => {
			company.categories.forEach((category) => {
				const categoryName = category.name.toLowerCase().trim();

				if (!categoriesMap.has(categoryName)) {
					categoriesMap.set(categoryName, {
						name: category.name,
						totalFrequency: category.frequency,
						averageSeverity: category.severity,
						companyCount: 1,
						percentage: 0 // Will calculate after aggregation
					});
				} else {
					const existing = categoriesMap.get(categoryName)!;
					existing.totalFrequency += category.frequency;
					existing.averageSeverity =
						(existing.averageSeverity * existing.companyCount + category.severity) /
						(existing.companyCount + 1);
					existing.companyCount += 1;
				}
			});
		});

		// Calculate percentages and convert to array
		const result = Array.from(categoriesMap.values()).map((category) => {
			category.percentage = (category.totalFrequency / totalFrequencyAcrossAll) * 100;
			return category;
		});

		// Sort by total frequency (descending)
		return result.sort((a, b) => b.totalFrequency - a.totalFrequency);
	}, [data]);

	if (industryTrends.length === 0) {
		return (
			<div className="text-center p-6 bg-gray-50 dark:bg-gray-700 rounded-lg">
				<p className="text-gray-600 dark:text-gray-400">No industry trends data available.</p>
			</div>
		);
	}

	// Get top 5 trends for the chart
	const topTrends = industryTrends.slice(0, 5);
	const maxFrequency = Math.max(...topTrends.map((trend) => trend.totalFrequency));

	return (
		<div>
			<div className="mb-4">
				<h3 className="text-lg font-medium text-gray-800 dark:text-white">
					Top Industry Pain Points
				</h3>
				<p className="text-sm text-gray-600 dark:text-gray-400">
					The most significant pain points affecting the industry as a whole
				</p>
			</div>

			{/* Bar chart visualization */}
			<div className="mt-6 space-y-4">
				{topTrends.map((trend, index) => (
					<div key={index} className="relative">
						<div className="flex items-center mb-1">
							<span className="text-sm font-medium text-gray-700 dark:text-gray-300 w-1/4 truncate pr-2">
								{trend.name}
							</span>
							<div className="w-3/4 flex items-center">
								<div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-6 overflow-hidden">
									<div
										className="bg-blue-600 h-6 flex items-center pl-2 text-xs text-white font-medium"
										style={{ width: `${(trend.totalFrequency / maxFrequency) * 100}%` }}
									>
										{trend.totalFrequency}
									</div>
								</div>
							</div>
						</div>
						<div className="flex justify-between text-xs text-gray-500 dark:text-gray-400 pl-1/4 ml-2">
							<span>Severity: {trend.averageSeverity.toFixed(1)}</span>
							<span>
								In {trend.companyCount} of {data.length} companies
							</span>
						</div>
					</div>
				))}
			</div>

			{/* Table with all trends */}
			<div className="mt-8 overflow-x-auto">
				<table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
					<thead className="bg-gray-50 dark:bg-gray-800">
						<tr>
							<th
								scope="col"
								className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
							>
								Pain Point
							</th>
							<th
								scope="col"
								className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
							>
								Frequency
							</th>
							<th
								scope="col"
								className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
							>
								Severity
							</th>
							<th
								scope="col"
								className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
							>
								Companies
							</th>
							<th
								scope="col"
								className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
							>
								% of Total
							</th>
						</tr>
					</thead>
					<tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-800">
						{industryTrends.map((trend, index) => (
							<tr
								key={index}
								className={
									index % 2 === 0 ? 'bg-white dark:bg-gray-900' : 'bg-gray-50 dark:bg-gray-800'
								}
							>
								<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
									{trend.name}
								</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
									{trend.totalFrequency}
								</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
									{trend.averageSeverity.toFixed(1)}
								</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
									{trend.companyCount} of {data.length}
								</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
									{trend.percentage.toFixed(1)}%
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default IndustryTrendsChart;
