import React, { useState, useEffect, useRef } from 'react';
import { PainPointsAnalysis, TreemapNode } from '../../types/analysisTypes';
import { TweetData } from '../../pages/dashboard/DashboardPage';
import PainPointsListView from './PainPointsListView';

interface PainPointsChartProps {
	painPointsAnalysis: PainPointsAnalysis;
	tweets: TweetData[];
	onSelectTweet: (tweetIds: string[], categoryName?: string) => void;
	onToggleView?: () => void;
}

// Modern tooltip component with enhanced styling
const ModernTooltip = ({ item }: { item: any }) => {
	return (
		<div className="bg-white dark:bg-gray-800 p-4 rounded-xl shadow-xl border border-gray-100 dark:border-gray-700 max-w-[350px] transform transition-all duration-200 animate-fadeIn backdrop-blur-sm">
			<h3 className="font-bold text-lg mb-2 text-gray-900 dark:text-white">
				{item.name || 'Unknown'}
			</h3>
			{item.description && (
				<p className="text-sm text-gray-600 dark:text-gray-300 mb-3 leading-relaxed">
					{item.description}
				</p>
			)}
			<div className="flex items-center mb-3 bg-gray-50 dark:bg-gray-700 p-2 rounded-lg">
				<span className="text-sm font-medium mr-2 text-gray-700 dark:text-gray-300">
					Frequency:
				</span>
				<span className="text-sm font-bold bg-indigo-100 dark:bg-indigo-900 text-indigo-800 dark:text-indigo-200 px-2 py-1 rounded-md">
					{item.value || 0} tweets
				</span>
			</div>
			{typeof item.severity === 'number' && (
				<div className="flex items-center bg-gray-50 dark:bg-gray-700 p-2 rounded-lg">
					<span className="text-sm font-medium mr-2 text-gray-700 dark:text-gray-300">
						Severity:
					</span>
					<div className="flex">
						{[...Array(5)].map((_, i) => (
							<svg
								key={i}
								className={`w-4 h-4 ${
									i < item.severity
										? 'text-red-500 dark:text-red-400 drop-shadow-sm'
										: 'text-gray-200 dark:text-gray-600'
								}`}
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
							</svg>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

const PainPointsChart: React.FC<PainPointsChartProps> = ({
	painPointsAnalysis,
	tweets,
	onSelectTweet,
	onToggleView
}) => {
	const [treemapData, setTreemapData] = useState<TreemapNode[]>([]);
	const [hoveredItem, setHoveredItem] = useState<any>(null);
	const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
	const [tooltipVisible, setTooltipVisible] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
	const [showingSubcategories, setShowingSubcategories] = useState<boolean>(false);
	const [selectedNode, setSelectedNode] = useState<string | null>(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [viewMode, setViewMode] = useState<'chart' | 'list'>('chart');
	const chartRef = useRef<HTMLDivElement>(null);

	// Enhanced color function with gradients
	const getSeverityColor = (severity: number) => {
		const colors = [
			'linear-gradient(135deg, rgb(254, 240, 217) 0%, rgb(253, 235, 200) 100%)', // Severity 1 (lightest)
			'linear-gradient(135deg, rgb(253, 212, 158) 0%, rgb(253, 202, 138) 100%)',
			'linear-gradient(135deg, rgb(253, 187, 132) 0%, rgb(252, 172, 107) 100%)',
			'linear-gradient(135deg, rgb(252, 141, 89) 0%, rgb(251, 121, 69) 100%)',
			'linear-gradient(135deg, rgb(227, 74, 51) 0%, rgb(215, 48, 31) 100%)' // Severity 5 (darkest)
		];
		return colors[Math.min(Math.max(Math.floor(severity) - 1, 0), 4)];
	};

	// Transform pain points data into treemap format
	useEffect(() => {
		// Reset loading state when new data comes in
		setIsLoaded(false);
		setShowingSubcategories(false);
		setSelectedCategory(null);

		if (
			painPointsAnalysis &&
			painPointsAnalysis.categories &&
			painPointsAnalysis.categories.length > 0
		) {
			try {
				console.log('Raw pain points data:', painPointsAnalysis);

				// Create a flatter structure that works better with our visualization
				const rootNode: TreemapNode = {
					name: 'Pain Points',
					children: []
				};

				// Only add main categories by default
				painPointsAnalysis.categories.forEach((category) => {
					console.log(
						`Processing category: ${category.category} with ${category.tweet_ids.length} tweets`
					);

					// Add the main category with aggregated data from all subcategories
					rootNode.children?.push({
						name: category.category || 'Unknown Category',
						description: category.description || '',
						value: category.tweet_ids.length,
						frequency: category.frequency || 0,
						severity: typeof category.severity === 'number' ? category.severity : 1,
						tweet_ids: category.tweet_ids || [],
						category: category.category,
						hasSubcategories: category.subcategories && category.subcategories.length > 0,
						subcategories: category.subcategories || []
					});
				});

				// Ensure we have a valid children array
				if (!rootNode.children || rootNode.children.length === 0) {
					console.warn('No valid pain points data found, adding default node');

					// Add a default node if no valid data
					rootNode.children = [
						{
							name: 'No detailed data available',
							value: 1,
							severity: 1,
							category: 'Unknown'
						}
					];
				}

				console.log('Transformed pain points data:', rootNode);
				setTreemapData([rootNode]);

				// Add a small delay to allow for animation
				setTimeout(() => {
					setIsLoaded(true);
				}, 300);
			} catch (error) {
				console.error('Error transforming pain points data:', error);
				// Set a default treemap data with an error message
				setTreemapData([
					{
						name: 'Error',
						children: [
							{
								name: 'Error processing data',
								value: 1,
								severity: 1,
								category: 'Error'
							}
						]
					}
				]);
				setIsLoaded(true);
			}
		} else {
			console.warn('No pain points data or categories available');

			// Reset treemap data if no valid categories
			setTreemapData([]);
			setIsLoaded(true);
		}
	}, [painPointsAnalysis]); // Depend on painPointsAnalysis for real data

	// Handle category selection to show subcategories
	const handleCategorySelect = (categoryName: string) => {
		setSelectedCategory(categoryName);

		// Find the selected category in the data
		const selectedCategoryData = painPointsAnalysis.categories.find(
			(cat) => cat.category === categoryName
		);

		if (selectedCategoryData) {
			// Always show tweets for this category, regardless of subcategories
			onSelectTweet(
				selectedCategoryData.tweet_ids || [],
				selectedCategoryData.category || 'Unknown Category'
			);
		}
	};

	// Handle going back to main categories view
	const handleBackToMainCategories = () => {
		setSelectedCategory(null);
		setShowingSubcategories(false);

		// Reset to main categories view
		const rootNode: TreemapNode = {
			name: 'Pain Points',
			children: []
		};

		// Add main categories
		painPointsAnalysis.categories.forEach((category) => {
			rootNode.children?.push({
				name: category.category || 'Unknown Category',
				description: category.description || '',
				value: category.tweet_ids.length,
				frequency: category.frequency || 0,
				severity: typeof category.severity === 'number' ? category.severity : 1,
				tweet_ids: category.tweet_ids || [],
				category: category.category,
				hasSubcategories: category.subcategories && category.subcategories.length > 0,
				subcategories: category.subcategories || []
			});
		});

		setTreemapData([rootNode]);
	};

	// Handle node click in the treemap
	const handleNodeClick = (item: any) => {
		// Hide tooltip when clicking
		setHoveredItem(null);

		if (item.children) {
			// If clicking on the root node, do nothing
			return;
		}

		if (item.isSubcategory) {
			// If clicking on a subcategory, show tweets for this subcategory
			onSelectTweet(item.tweet_ids || [], `${item.category}: ${item.name}`);
		} else {
			// Always show tweets for the category, regardless of subcategories
			handleCategorySelect(item.category);
		}
	};

	// Handle mouse enter for tooltip
	const handleMouseEnter = (e: React.MouseEvent, item: any) => {
		if (item.children) {
			// Don't show tooltip for the root node
			return;
		}

		// Set position relative to the mouse cursor with a small offset
		setTooltipPosition({
			x: e.clientX,
			y: e.clientY
		});
		setHoveredItem(item);
	};

	// Handle mouse leave for tooltip
	const handleMouseLeave = () => {
		setHoveredItem(null);
	};

	// Handle clicking outside of nodes to close tooltip
	const handleChartClick = (e: React.MouseEvent) => {
		// Check if the click is directly on the chart background (not on a node)
		if (e.currentTarget === e.target) {
			setHoveredItem(null);
		}
	};

	// Virtualized rendering for treemap nodes
	const renderTreemapNodes = () => {
		if (!treemapData[0]?.children) return null;

		return treemapData[0].children.map((item, index) => {
			// Skip rendering if item has no value or is too small
			if (!item.value || item.value < 1) return null;

			// Calculate size based on value relative to total
			const totalValue =
				treemapData[0].children?.reduce((sum, child) => sum + (child.value || 0), 0) || 1;
			const sizePercentage = ((item.value || 0) / totalValue) * 100;

			// Skip tiny items that would be too small to display properly
			if (sizePercentage < 0.5) return null;

			// Determine size class based on percentage
			let sizeClass = 'h-24';
			let textSizeClass = 'text-sm';

			if (sizePercentage > 30) {
				sizeClass = 'h-64';
				textSizeClass = 'text-xl';
			} else if (sizePercentage > 20) {
				sizeClass = 'h-56';
				textSizeClass = 'text-lg';
			} else if (sizePercentage > 10) {
				sizeClass = 'h-48';
				textSizeClass = 'text-base';
			} else if (sizePercentage > 5) {
				sizeClass = 'h-40';
				textSizeClass = 'text-base';
			} else if (sizePercentage > 2) {
				sizeClass = 'h-32';
				textSizeClass = 'text-sm';
			}

			// Check if this item is selected
			const isSelected = selectedCategory === item.category;

			return (
				<div
					key={`node-${index}`}
					className={`relative rounded-lg p-4 cursor-pointer transition-all duration-300 ease-in-out flex flex-col justify-between ${sizeClass} ${
						isSelected
							? 'ring-2 ring-indigo-500 dark:ring-indigo-400 ring-offset-1 dark:ring-offset-gray-800 shadow-lg'
							: ''
					}`}
					style={{
						background: getSeverityColor(item.severity || 1),
						flexGrow: item.value || 1,
						flexBasis: `${Math.max(sizePercentage, 2)}%`,
						minWidth: '150px' // Minimum width to ensure readability
					}}
					onClick={() => {
						handleNodeClick(item);
						// Hide tooltip when clicking on a node
						setHoveredItem(null);
					}}
					onMouseEnter={(e) => handleMouseEnter(e, item)}
					onMouseLeave={handleMouseLeave}
				>
					<div className="flex flex-col h-full justify-center items-center">
						<div>
							<h3
								className={`font-bold text-white/90
								 ${textSizeClass} line-clamp-2 text-center mb-2`}
							>
								{item.name}
							</h3>
							{item.description && sizePercentage > 5 && (
								<p className="text-sm text-white/70 mt-1 line-clamp-2 text-center">
									{item.description}
								</p>
							)}
						</div>

						<div className="mt-auto">
							<div className="bg-white/30 rounded-full px-3 py-1.5 text-sm font-medium text-gray-700 dark:text-gray-300 shadow-sm flex items-center justify-center w-min">
								<span className="text-nowrap text-xs text-white">
									{item.value} {item.value === 1 ? 'issue' : 'issues'}
								</span>
							</div>
						</div>

						{isSelected && (
							<div className="absolute -top-1 -right-1 bg-indigo-500 dark:bg-indigo-400 rounded-full p-1 shadow-md">
								<svg className="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 20 20">
									<path
										fillRule="evenodd"
										d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
										clipRule="evenodd"
									/>
								</svg>
							</div>
						)}
					</div>
				</div>
			);
		});
	};

	// Render the component
	return (
		<div className="w-full bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 overflow-hidden">
			<div className="p-4 border-b border-gray-100 dark:border-gray-700 bg-gray-50 dark:bg-gray-800/80 flex justify-between items-center">
				<h3 className="font-medium text-gray-700 dark:text-gray-300">
					{showingSubcategories && selectedCategory
						? `${selectedCategory} - Subcategories`
						: 'Pain Points Analysis'}
				</h3>

				<div className="flex items-center space-x-2">
					{showingSubcategories && (
						<button
							onClick={handleBackToMainCategories}
							className="flex items-center text-xs font-medium text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-300 transition-colors"
						>
							<svg
								className="w-4 h-4 mr-1"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M10 19l-7-7m0 0l7-7m-7 7h18"
								/>
							</svg>
							Back to Categories
						</button>
					)}
				</div>
			</div>

			{viewMode === 'chart' ? (
				<div
					ref={chartRef}
					className="relative p-6 min-h-[500px] overflow-hidden"
					onClick={handleChartClick}
				>
					{isLoaded ? (
						<div className="flex flex-wrap gap-3 overflow-y-auto max-h-[calc(100vh-200px)] p-2">
							{renderTreemapNodes()}
						</div>
					) : (
						<div className="flex justify-center items-center h-[400px]">
							<div className="animate-pulse flex flex-col items-center">
								<div className="h-32 w-32 bg-gray-200 dark:bg-gray-700 rounded-lg mb-4"></div>
								<div className="h-4 w-48 bg-gray-200 dark:bg-gray-700 rounded"></div>
							</div>
						</div>
					)}

					{/* Tooltip */}
					{hoveredItem && (
						<div
							className="fixed z-50 pointer-events-none"
							style={{
								left: `${tooltipPosition.x + 15}px`,
								top: `${tooltipPosition.y + 15}px`
							}}
						>
							<ModernTooltip item={hoveredItem} />
						</div>
					)}
				</div>
			) : (
				<PainPointsListView
					painPointsAnalysis={painPointsAnalysis}
					tweets={tweets}
					onSelectTweet={onSelectTweet}
				/>
			)}
		</div>
	);
};

export default PainPointsChart;
