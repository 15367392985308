import React from 'react';
import { TweetData } from '../pages/dashboard/DashboardPage';

interface TweetCardProps {
	tweet: TweetData;
}

const TweetCard: React.FC<TweetCardProps> = ({ tweet }) => {
	// Format date
	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		return date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit'
		});
	};

	// Format numbers for engagement metrics
	const formatNumber = (num: number) => {
		if (num >= 1000000) {
			return (num / 1000000).toFixed(1) + 'M';
		}
		if (num >= 1000) {
			return (num / 1000).toFixed(1) + 'K';
		}
		return num.toString();
	};

	// Get sentiment badge color
	const getSentimentBadgeClass = () => {
		if (!tweet.sentiment) return 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300';

		return tweet.sentiment === 'positive'
			? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
			: 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300';
	};

	return (
		<div
			className={`border ${
				tweet.sentiment === 'negative'
					? 'border-red-200 dark:border-red-800'
					: tweet.sentiment === 'positive'
					? 'border-green-200 dark:border-green-800'
					: 'border-gray-200 dark:border-gray-700'
			} rounded-lg p-4 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors`}
		>
			<div className="flex items-start">
				{tweet.user_profile?.profile_image && (
					<img
						src={tweet.user_profile.profile_image}
						alt={tweet.author}
						className="w-12 h-12 rounded-full mr-3"
					/>
				)}
				<div className="flex-1 min-w-0">
					<div className="flex justify-between items-start">
						<div>
							<div className="font-medium text-gray-900 dark:text-white">{tweet.author}</div>
							<div className="text-sm text-gray-500 dark:text-gray-400">@{tweet.handle}</div>
						</div>
						<div className="flex items-center gap-2">
							{tweet.sentiment && (
								<span
									className={`px-2 py-1 text-xs font-medium rounded-full ${getSentimentBadgeClass()}`}
								>
									{tweet.sentiment === 'positive' ? 'Positive' : 'Negative'}
								</span>
							)}
							<a
								href={tweet.tweet_url}
								target="_blank"
								rel="noopener noreferrer"
								className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5"
									fill="currentColor"
									viewBox="0 0 24 24"
								>
									<path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
								</svg>
							</a>
						</div>
					</div>
					<div className="mt-2 text-gray-800 dark:text-gray-200 whitespace-pre-wrap">
						{tweet.content}
					</div>
					<div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
						{formatDate(tweet.timestamp)}
					</div>
					<div className="mt-3 flex space-x-4 text-sm text-gray-500 dark:text-gray-400">
						<div className="flex items-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-4 w-4 mr-1"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
								/>
							</svg>
							{formatNumber(tweet.likes)}
						</div>
						<div className="flex items-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-4 w-4 mr-1"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
								/>
							</svg>
							{formatNumber(tweet.retweets)}
						</div>
						<div className="flex items-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-4 w-4 mr-1"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
								/>
							</svg>
							{formatNumber(tweet.replies)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TweetCard;
