import React from 'react';

interface AnalysisButtonProps {
	onAnalyze: () => void;
	onViewAnalysis?: () => void;
	disabled: boolean;
	loading: boolean;
	hasAnalyzed?: boolean;
	hasApiKey?: boolean;
	progress?: {
		status: string;
		message: string;
		progress: number;
		stage?: string;
	} | null;
	label?: string;
}

const AnalysisButton: React.FC<AnalysisButtonProps> = ({
	onAnalyze,
	onViewAnalysis,
	disabled,
	loading,
	hasAnalyzed = false,
	hasApiKey = true,
	progress = null,
	label = 'Analyze Results'
}) => {
	// If hasAnalyzed is true and onViewAnalysis is provided, show "View Analysis" button
	if (hasAnalyzed && onViewAnalysis) {
		return (
			<button
				onClick={onViewAnalysis}
				className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 w-full"
			>
				View Analysis
			</button>
		);
	}

	// Determine if the button should be disabled
	const isDisabled = disabled || loading || !hasApiKey;

	// Determine the button tooltip based on the disabled reason
	const getTooltip = () => {
		if (!hasApiKey)
			return 'OpenAI API key is required. Please add your API key in the Settings page.';
		if (loading) return 'Analysis in progress...';
		if (disabled) return 'Analysis not available';
		return '';
	};

	// Helper function to get a simplified message for the progress bar
	const getSimplifiedMessage = () => {
		if (!progress) return '';

		return progress.message || 'Processing...';
	};

	return (
		<div className="flex flex-col w-full">
			{/* Progress bar */}
			{loading && progress && (
				<div className="mb-2">
					<div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
						<div
							className="bg-blue-600 h-2.5 rounded-full"
							style={{ width: `${Math.max(5, progress.progress)}%` }}
						></div>
					</div>
					<div className="flex justify-between items-center mt-1">
						<span className="text-xs text-gray-600 dark:text-gray-400">
							{getSimplifiedMessage()}
						</span>
						<span className="text-xs font-medium text-gray-700 dark:text-gray-300">
							{Math.round(progress.progress)}%
						</span>
					</div>
				</div>
			)}

			{/* Button */}
			<div className="relative group">
				<button
					onClick={onAnalyze}
					disabled={isDisabled}
					className={`text-nowrap w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 ${
						loading
							? 'bg-blue-500 text-white cursor-not-allowed opacity-70'
							: isDisabled
							? 'bg-gray-300 dark:bg-gray-700 text-gray-500 dark:text-gray-400 cursor-not-allowed'
							: 'bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-500'
					}`}
				>
					{loading ? (
						<div className="flex items-center justify-center">
							<svg
								className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<circle
									className="opacity-25"
									cx="12"
									cy="12"
									r="10"
									stroke="currentColor"
									strokeWidth="4"
								></circle>
								<path
									className="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								></path>
							</svg>
							Analyzing...
						</div>
					) : (
						`2. ${label}`
					)}
				</button>

				{isDisabled && !loading && (
					<div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
						{getTooltip()}
					</div>
				)}
			</div>
		</div>
	);
};

export default AnalysisButton;
