import React from 'react';
import { Button } from './ui/ScraperUI';
import LoadingSpinner from './LoadingSpinner';
import ProgressBar from './ProgressBar';

interface ClassificationButtonProps {
	onClassify: () => void;
	disabled: boolean;
	loading: boolean;
	hasApiKey?: boolean;
	progress: {
		status: string;
		message: string;
		progress: number;
		stage?: string;
	} | null;
}

const ClassificationButton: React.FC<ClassificationButtonProps> = ({
	onClassify,
	disabled,
	loading,
	hasApiKey = true,
	progress
}) => {
	// Determine if the button should be disabled
	const isDisabled = disabled || loading || !hasApiKey;

	// Determine the button tooltip based on the disabled reason
	const getTooltip = () => {
		if (!hasApiKey)
			return 'OpenAI API key is required. Please add your API key in the Settings page.';
		if (loading) return 'Classification in progress...';
		if (disabled) return 'Classification not available';
		return '';
	};

	return (
		<div className="flex flex-col w-full">
			{loading && progress && (
				<div className="mb-2">
					<ProgressBar progress={progress.progress} />
					<div className="text-xs text-gray-600 dark:text-gray-400 mt-1">
						{progress.message}
						{progress.stage && <span className="ml-1 text-nowrap">({progress.stage})</span>}
					</div>
				</div>
			)}

			<div className="relative group">
				<Button
					onClick={onClassify}
					disabled={isDisabled}
					className="w-full text-nowrap"
					color="primary"
				>
					{loading ? (
						<div className="flex items-center justify-center">
							<LoadingSpinner size="sm" />
							<span className="ml-2 text-nowrap">Classifying...</span>
						</div>
					) : (
						'1. Classify Tweets'
					)}
				</Button>

				{isDisabled && !loading && (
					<div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
						{getTooltip()}
					</div>
				)}
			</div>
		</div>
	);
};

export default ClassificationButton;
