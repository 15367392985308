import React, { useState, useEffect } from 'react';
import { Card, CardBody } from '../ui/ScraperUI';
import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { CompanyData, Category } from './types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface CommonPainPointsChartProps {
	data: CompanyData[];
	darkMode: boolean;
}

interface AggregatedPainPoint {
	name: string;
	description: string;
	totalFrequency: number;
	averageSeverity: number;
	companies: string[];
	companyPercentage: number;
}

const CommonPainPointsChart: React.FC<CommonPainPointsChartProps> = ({ data, darkMode }) => {
	const [aggregatedData, setAggregatedData] = useState<AggregatedPainPoint[]>([]);
	const [chartData, setChartData] = useState<any>(null);
	const [viewMode, setViewMode] = useState<'frequency' | 'severity' | 'companies'>('frequency');
	const [showSubcategories, setShowSubcategories] = useState(false);

	useEffect(() => {
		if (data && data.length > 0) {
			// Aggregate pain points across companies
			const painPointsMap = new Map<string, AggregatedPainPoint>();

			data.forEach((company) => {
				// Process categories
				company.categories.forEach((category) => {
					aggregatePainPoint(painPointsMap, category, company.name, data.length, false);

					// Process subcategories if enabled
					if (showSubcategories) {
						category.subcategories.forEach((subcategory) => {
							aggregatePainPoint(
								painPointsMap,
								{
									name: `${category.name}: ${subcategory.name}`,
									description: subcategory.description,
									frequency: subcategory.frequency,
									severity: subcategory.severity,
									subcategories: []
								},
								company.name,
								data.length,
								true
							);
						});
					}
				});
			});

			// Convert map to array and sort
			let sortedData = Array.from(painPointsMap.values());

			// Sort based on view mode
			if (viewMode === 'frequency') {
				sortedData.sort((a, b) => b.totalFrequency - a.totalFrequency);
			} else if (viewMode === 'severity') {
				sortedData.sort((a, b) => b.averageSeverity - a.averageSeverity);
			} else {
				sortedData.sort((a, b) => b.companies.length - a.companies.length);
			}

			// Limit to top 10 for better visualization
			sortedData = sortedData.slice(0, 10);

			setAggregatedData(sortedData);

			// Prepare chart data
			const labels = sortedData.map((point) => point.name);
			let datasets = [];

			if (viewMode === 'frequency') {
				datasets.push({
					label: 'Total Frequency',
					data: sortedData.map((point) => point.totalFrequency),
					backgroundColor: 'rgba(54, 162, 235, 0.7)',
					borderColor: 'rgba(54, 162, 235, 1)',
					borderWidth: 1
				});
			} else if (viewMode === 'severity') {
				datasets.push({
					label: 'Average Severity',
					data: sortedData.map((point) => point.averageSeverity),
					backgroundColor: 'rgba(255, 99, 132, 0.7)',
					borderColor: 'rgba(255, 99, 132, 1)',
					borderWidth: 1
				});
			} else {
				datasets.push({
					label: 'Companies Affected (%)',
					data: sortedData.map((point) => point.companyPercentage),
					backgroundColor: 'rgba(75, 192, 192, 0.7)',
					borderColor: 'rgba(75, 192, 192, 1)',
					borderWidth: 1
				});
			}

			setChartData({
				labels,
				datasets
			});
		}
	}, [data, viewMode, showSubcategories]);

	const aggregatePainPoint = (
		map: Map<string, AggregatedPainPoint>,
		item: Category,
		companyName: string,
		totalCompanies: number,
		isSubcategory: boolean
	) => {
		const key = isSubcategory ? item.name : item.name.toLowerCase();

		if (!map.has(key)) {
			map.set(key, {
				name: item.name,
				description: item.description,
				totalFrequency: item.frequency,
				averageSeverity: item.severity,
				companies: [companyName],
				companyPercentage: (1 / totalCompanies) * 100
			});
		} else {
			const existing = map.get(key)!;

			// Only add company if not already counted
			if (!existing.companies.includes(companyName)) {
				existing.companies.push(companyName);
				existing.companyPercentage = (existing.companies.length / totalCompanies) * 100;
			}

			existing.totalFrequency += item.frequency;

			// Recalculate average severity
			const totalSeverity =
				existing.averageSeverity * (existing.companies.length - 1) + item.severity;
			existing.averageSeverity = totalSeverity / existing.companies.length;
		}
	};

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		indexAxis: 'y' as const,
		scales: {
			x: {
				beginAtZero: true,
				grid: {
					color: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
				},
				ticks: {
					color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'
				}
			},
			y: {
				grid: {
					display: false
				},
				ticks: {
					color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'
				}
			}
		},
		plugins: {
			legend: {
				position: 'top' as const,
				labels: {
					color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'
				}
			},
			title: {
				display: true,
				text: 'Common Pain Points Across Companies',
				color: darkMode ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.9)'
			},
			tooltip: {
				callbacks: {
					afterLabel: function (context: any) {
						const index = context.dataIndex;
						const painPoint = aggregatedData[index];

						return [
							`Description: ${painPoint.description}`,
							`Total Frequency: ${painPoint.totalFrequency}`,
							`Average Severity: ${painPoint.averageSeverity.toFixed(1)}`,
							`Companies: ${painPoint.companies.join(', ')}`,
							`Company Percentage: ${painPoint.companyPercentage.toFixed(1)}%`
						];
					}
				}
			}
		}
	};

	if (!data || data.length === 0) {
		return (
			<div className="text-center p-6">
				<p className="text-gray-500 dark:text-gray-400">No data available for analysis</p>
			</div>
		);
	}

	return (
		<div>
			<div className="mb-4">
				<h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
					Common Pain Points
				</h3>

				<div className="flex flex-wrap gap-2 mb-4">
					<button
						onClick={() => setViewMode('frequency')}
						className={`px-3 py-1 rounded-md text-sm ${
							viewMode === 'frequency'
								? 'bg-blue-500 text-white'
								: 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
						}`}
					>
						By Frequency
					</button>
					<button
						onClick={() => setViewMode('severity')}
						className={`px-3 py-1 rounded-md text-sm ${
							viewMode === 'severity'
								? 'bg-blue-500 text-white'
								: 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
						}`}
					>
						By Severity
					</button>
					<button
						onClick={() => setViewMode('companies')}
						className={`px-3 py-1 rounded-md text-sm ${
							viewMode === 'companies'
								? 'bg-blue-500 text-white'
								: 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
						}`}
					>
						By Companies Affected
					</button>

					<div className="ml-auto flex items-center">
						<input
							type="checkbox"
							id="showSubcategories"
							checked={showSubcategories}
							onChange={() => setShowSubcategories(!showSubcategories)}
							className="mr-2"
						/>
						<label htmlFor="showSubcategories" className="text-sm text-gray-700 dark:text-gray-300">
							Include Subcategories
						</label>
					</div>
				</div>
			</div>

			<div className="h-[500px]">
				{chartData ? (
					<Bar data={chartData} options={chartOptions} />
				) : (
					<div className="flex items-center justify-center h-full">
						<p className="text-gray-500 dark:text-gray-400">Loading chart data...</p>
					</div>
				)}
			</div>

			{aggregatedData.length > 0 && (
				<div className="mt-6">
					<h4 className="text-lg font-medium mb-3 text-gray-800 dark:text-white">
						Pain Points Details
					</h4>
					<div className="overflow-x-auto">
						<table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
							<thead className="bg-gray-50 dark:bg-gray-800">
								<tr>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
										Pain Point
									</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
										Description
									</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
										Frequency
									</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
										Severity
									</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
										Companies
									</th>
								</tr>
							</thead>
							<tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-800">
								{aggregatedData.map((point, index) => (
									<tr key={index} className={index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-800' : ''}>
										<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
											{point.name}
										</td>
										<td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
											{point.description}
										</td>
										<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
											{point.totalFrequency}
										</td>
										<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
											{point.averageSeverity.toFixed(1)}
										</td>
										<td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
											<span className="font-medium">{point.companies.length}</span> (
											{point.companyPercentage.toFixed(1)}%)
											<div className="text-xs mt-1">{point.companies.join(', ')}</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)}
		</div>
	);
};

export default CommonPainPointsChart;
