import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getUserProfile, updateOpenAIKey, UserProfile } from '../utils/supabaseClient';

interface UseUserProfileReturn {
	profile: UserProfile | null;
	loading: boolean;
	error: Error | null;
	updateOpenAIKey: (apiKey: string) => Promise<{ success: boolean; error: Error | null }>;
	refreshProfile: () => Promise<void>;
}

export const useUserProfile = (): UseUserProfileReturn => {
	const [profile, setProfile] = useState<UserProfile | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);

	const { user } = useAuth();

	const fetchProfile = async () => {
		if (!user) {
			setProfile(null);
			setLoading(false);
			return;
		}

		console.log('user', user);

		try {
			setLoading(true);
			setError(null);

			const { data, error: fetchError } = await getUserProfile(user.id);

			if (fetchError) {
				throw new Error(fetchError.message);
			}

			setProfile(data as UserProfile);
		} catch (err) {
			console.error('Error fetching user profile:', err);
			setError(err instanceof Error ? err : new Error('Failed to fetch user profile'));
		} finally {
			setLoading(false);
		}
	};

	// Fetch profile on mount and when user changes
	useEffect(() => {
		fetchProfile();
	}, [user]);

	// Update OpenAI API key
	const handleUpdateOpenAIKey = async (apiKey: string) => {
		if (!user) {
			return {
				success: false,
				error: new Error('You must be logged in to update settings')
			};
		}

		try {
			const { error: updateError } = await updateOpenAIKey(user.id, apiKey);

			if (updateError) {
				throw new Error(updateError.message);
			}

			// Update local state
			setProfile((prev) => (prev ? { ...prev, openai_key: apiKey } : null));

			return { success: true, error: null };
		} catch (err) {
			console.error('Error updating OpenAI API key:', err);
			return {
				success: false,
				error: err instanceof Error ? err : new Error('Failed to update OpenAI API key')
			};
		}
	};

	return {
		profile,
		loading,
		error,
		updateOpenAIKey: handleUpdateOpenAIKey,
		refreshProfile: fetchProfile
	};
};
