import { AnalysisResponse } from '../types/analysisTypes';
import { TweetData } from '../pages/dashboard/DashboardPage';

const getApiUrl = () => {
	return process.env.REACT_APP_API_URL || '';
};

/**
 * Generate a comprehensive report on pain points
 * @param analysisData The pain points analysis data
 * @param tweets The tweets data
 * @param companyName The company name
 * @param openaiApiKey The OpenAI API key
 * @returns Promise with the report generation result
 */
export const generateReport = async (
	analysisData: AnalysisResponse,
	tweets: TweetData[],
	companyName: string,
	openaiApiKey?: string
): Promise<{ success: boolean; message: string; reportId?: string }> => {
	try {
		// Check if OpenAI API key is provided
		if (!openaiApiKey) {
			return {
				success: false,
				message: 'OpenAI API key is required. Please add your API key in the Settings page.'
			};
		}

		// Prepare the data for the API
		const painPointsAnalysis = analysisData.pain_points_analysis;

		// Convert the pain points analysis to the format expected by the backend
		const categories = painPointsAnalysis.categories.map((category) => ({
			name: category.category,
			description: category.description,
			frequency: category.frequency,
			severity: category.severity,
			subcategories: category.subcategories.map((subcategory) => ({
				name: subcategory.name,
				description: subcategory.description,
				frequency: subcategory.frequency,
				severity: subcategory.severity,
				tweetIds: subcategory.tweet_ids
			}))
		}));

		// Create the request payload
		const payload = {
			analysisData: {
				categories,
				profileHandle: companyName
			},
			tweets,
			companyName,
			openAIApiKey: openaiApiKey
		};

		console.log('Sending report generation request');

		// Send the request to the API
		const response = await fetch(`${getApiUrl()}/report/generate`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(payload)
		});

		const data = await response.json();
		console.log('Report generation response:', data);

		if (!response.ok) {
			throw new Error(data.message || 'Failed to generate report');
		}

		return {
			success: true,
			message: data.message,
			reportId: data.reportId
		};
	} catch (error: any) {
		console.error('Error generating report:', error);
		return {
			success: false,
			message: error.message || 'An error occurred during report generation'
		};
	}
};

/**
 * Get the progress of a report generation
 * @param reportId The ID of the report to check
 * @returns Promise with the progress information
 */
export const getReportProgress = async (
	reportId: string
): Promise<{
	success: boolean;
	status?: string;
	progress?: number;
	message?: string;
	stage?: string;
}> => {
	try {
		console.log(`Checking report progress for ID: ${reportId}`);
		const response = await fetch(`${getApiUrl()}/report/progress/${reportId}`);

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || 'Failed to get report progress');
		}

		const data = await response.json();
		console.log('Report progress response:', data);
		return data;
	} catch (error: any) {
		console.error('Error getting report progress:', error);
		return {
			success: false,
			message: error.message || 'An error occurred while checking report progress'
		};
	}
};

/**
 * Get the content of a generated report
 * @param reportId The ID of the report to get
 * @returns Promise with the report content
 */
export const getReportContent = async (
	reportId: string
): Promise<{ success: boolean; reportContent?: string; message?: string }> => {
	try {
		console.log(`Fetching report content for ID: ${reportId}`);
		const response = await fetch(`${getApiUrl()}/report/content/${reportId}`);

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || 'Failed to get report content');
		}

		const data = await response.json();
		console.log('Report content response:', data);

		// Check all possible property names the backend might use
		const content = data.content || data.reportContent || data.text || '';

		return {
			success: true,
			reportContent: content
		};
	} catch (error: any) {
		console.error('Error getting report content:', error);
		return {
			success: false,
			message: error.message || 'An error occurred while getting report content'
		};
	}
};

/**
 * Download a generated report as a markdown file
 * @param reportId The ID of the report to download
 */
export const downloadReport = (reportId: string): void => {
	// Create a link to download the report
	const link = document.createElement('a');
	link.href = `${getApiUrl()}/report/download/${reportId}`;
	link.target = '_blank';
	link.download = `pain_point_report.md`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
