import React, { useEffect, useState } from 'react';
import { downloadReport, getReportProgress, getReportContent } from '../../utils/reportUtils';
import ReactMarkdown from 'react-markdown';
import '../../styles/markdown.css'; // Import our custom markdown styling

interface ReportModalProps {
	isOpen: boolean;
	onClose: () => void;
	reportId?: string;
	isGenerating: boolean;
	error?: string;
}

const ReportModal: React.FC<ReportModalProps> = ({
	isOpen,
	onClose,
	reportId,
	isGenerating,
	error
}) => {
	const [progress, setProgress] = useState<number>(0);
	const [status, setStatus] = useState<string>('');
	const [reportContent, setReportContent] = useState<string>('');
	const [loadingContent, setLoadingContent] = useState<boolean>(false);
	const [contentError, setContentError] = useState<string | null>(null);

	// Reset state when modal is closed
	useEffect(() => {
		if (!isOpen) {
			setProgress(0);
			setStatus('');
			setContentError(null);
			// Don't reset reportContent to preserve it between modal openings
		}
	}, [isOpen]);

	// Fetch report content when modal is opened with a reportId and not generating
	useEffect(() => {
		if (isOpen && reportId && !isGenerating) {
			fetchReportContent();
		}
	}, [isOpen, reportId, isGenerating]);

	useEffect(() => {
		let progressInterval: NodeJS.Timeout | null = null;

		const fetchProgress = async () => {
			if (!reportId || !isGenerating) return;

			try {
				console.log(`Fetching progress for report ID: ${reportId}`);
				const progressData = await getReportProgress(reportId);
				console.log('Progress data:', progressData);

				if (progressData.success) {
					setProgress(progressData.progress || 0);
					setStatus(progressData.status || '');

					// If the report is complete, fetch the content
					if (progressData.status === 'completed') {
						fetchReportContent();

						// Clear the interval once complete
						if (progressInterval) {
							clearInterval(progressInterval);
							progressInterval = null;
						}
					}
				}
			} catch (err) {
				console.error('Error fetching report progress:', err);
			}
		};

		// If we have a reportId and are generating, start polling for progress
		if (reportId && isGenerating) {
			fetchProgress(); // Fetch immediately
			progressInterval = setInterval(fetchProgress, 2000); // Then every 2 seconds
		}

		return () => {
			if (progressInterval) {
				clearInterval(progressInterval);
			}
		};
	}, [reportId, isGenerating]);

	const fetchReportContent = async () => {
		if (!reportId) return;

		setLoadingContent(true);
		setContentError(null);

		try {
			console.log(`Fetching content for report ID: ${reportId}`);
			const contentData = await getReportContent(reportId);
			console.log('Content data:', contentData);

			if (contentData.success && contentData.reportContent) {
				setReportContent(contentData.reportContent);
			} else {
				setContentError(contentData.message || 'Failed to load report content');
			}
		} catch (err: any) {
			console.error('Error fetching report content:', err);
			setContentError(err.message || 'An error occurred while loading the report');
		} finally {
			setLoadingContent(false);
		}
	};

	if (!isOpen) return null;

	const handleDownload = () => {
		if (!reportId) return;
		downloadReport(reportId);
	};

	const handleRetry = () => {
		fetchReportContent();
	};

	return (
		<div className="fixed inset-0 z-50 overflow-y-auto">
			<div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<div className="fixed inset-0 transition-opacity" aria-hidden="true">
					<div className="absolute inset-0 bg-gray-500 dark:bg-gray-900 opacity-75"></div>
				</div>

				<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
					&#8203;
				</span>

				<div
					className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
					role="dialog"
					aria-modal="true"
					aria-labelledby="modal-headline"
				>
					<div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
						<div className="sm:flex sm:items-start">
							<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
								<h3
									className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
									id="modal-headline"
								>
									{isGenerating ? 'Generating Report...' : error ? 'Error' : 'Report Generated'}
								</h3>
								<div className="mt-4">
									{isGenerating ? (
										<div className="flex flex-col items-center justify-center py-6">
											<div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5 mb-4">
												<div
													className="bg-indigo-600 h-2.5 rounded-full"
													style={{ width: `${progress}%` }}
												></div>
											</div>
											<p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
												{status || 'Initializing report generation...'}
											</p>
											<p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
												Please wait while we generate your comprehensive report. This may take a few
												moments...
											</p>
										</div>
									) : error ? (
										<div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-md p-4 text-red-700 dark:text-red-300">
											<p>{error}</p>
										</div>
									) : loadingContent ? (
										<div className="flex flex-col items-center justify-center py-6">
											<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
											<p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
												Loading report content...
											</p>
										</div>
									) : contentError ? (
										<div className="flex flex-col items-center justify-center py-6">
											<div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-md p-4 text-red-700 dark:text-red-300 mb-4 w-full">
												<p>{contentError}</p>
											</div>
											<button
												onClick={handleRetry}
												className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
											>
												Retry Loading
											</button>
										</div>
									) : (
										<div>
											<div className="mb-4">
												<h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
													Report Content
												</h4>
												<div className="bg-gray-100 dark:bg-gray-800 rounded-md p-4 max-h-[60vh] overflow-y-auto border border-gray-200 dark:border-gray-700">
													{reportContent ? (
														<div className="markdown-body dark:markdown-body-dark">
															<ReactMarkdown>{reportContent}</ReactMarkdown>
														</div>
													) : (
														<p className="text-gray-500 dark:text-gray-400">
															No content available. The report may still be generating.
														</p>
													)}
												</div>
											</div>

											<div className="mb-4">
												<p className="text-sm text-gray-600 dark:text-gray-400">
													Your report can be downloaded as a markdown (.md) file, which can be
													viewed in many text editors or markdown viewers.
												</p>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
						{!isGenerating && !error && !loadingContent && !contentError && reportContent && (
							<button
								type="button"
								className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
								onClick={handleDownload}
							>
								Download Report
							</button>
						)}
						<button
							type="button"
							className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-800 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
							onClick={onClose}
						>
							{isGenerating ? 'Cancel' : 'Close'}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReportModal;
