import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { AuthCard, AuthHeader, AuthBody, Button, Alert, Link } from '../ui/AuthUI';

interface EmailVerificationProps {
	token: string;
	email: string;
	onBackToSignIn: () => void;
}

const EmailVerification: React.FC<EmailVerificationProps> = ({ token, email, onBackToSignIn }) => {
	// State
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);

	// Auth context
	const { verifyEmail } = useAuth();

	// Verify email on component mount
	useEffect(() => {
		const verify = async () => {
			if (!token || !email) {
				setError(
					'Invalid verification link. Please try again or request a new verification email.'
				);
				setIsLoading(false);
				return;
			}

			try {
				const { success, error } = await verifyEmail(token, email);

				if (success) {
					setSuccess(true);
				} else {
					setError(error.message || 'Failed to verify email. Please try again.');
				}
			} catch (error) {
				setError('An unexpected error occurred. Please try again.');
				console.error('Email verification error:', error);
			} finally {
				setIsLoading(false);
			}
		};

		verify();
	}, [token, email, verifyEmail]);

	return (
		<AuthCard>
			<AuthHeader title="Email Verification" subtitle="Verifying your email address" />
			<AuthBody>
				{isLoading ? (
					<div className="flex flex-col items-center justify-center py-6">
						<div className="w-12 h-12 border-t-2 border-b-2 border-indigo-500 rounded-full animate-spin"></div>
						<p className="mt-4 text-gray-600 dark:text-gray-400">Verifying your email...</p>
					</div>
				) : error ? (
					<>
						<Alert type="error" message={error} />
						<div className="mt-6">
							<Button onClick={onBackToSignIn}>Back to Sign In</Button>
						</div>
					</>
				) : success ? (
					<>
						<Alert type="success" message="Your email has been verified successfully!" />
						<div className="mt-6">
							<Button onClick={onBackToSignIn}>Continue to Sign In</Button>
						</div>
					</>
				) : null}
			</AuthBody>
		</AuthCard>
	);
};

export default EmailVerification;
