import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage: React.FC = () => {
	return (
		<div className="min-h-screen bg-gradient-to-br from-slate-50 to-slate-100 dark:from-gray-900 dark:to-gray-800">
			{/* Navigation */}
			<nav className="container mx-auto px-6 py-4">
				<div className="flex items-center justify-between">
					<div className="text-3xl font-bold">
						<span className="text-blue-600">X</span>pose
					</div>
					<div className="flex space-x-4">
						<Link
							to="/signin"
							className="px-4 py-2 text-gray-700 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 transition-colors"
						>
							Sign In
						</Link>
						<Link
							to="/signup"
							className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
						>
							Get Started
						</Link>
					</div>
				</div>
			</nav>

			{/* Hero Section */}
			<div className="container mx-auto px-6 py-16 md:py-24">
				<div className="flex flex-col items-center text-center justify-center">
					<div className="animate-fade-in-up flex flex-col items-center justify-center">
						<h1 className="text-5xl md:text-7xl font-bold text-gray-900 dark:text-white mb-6">
							Turn Competitor Complaints into
							<span className="text-blue-600"> Your Opportunities</span>
						</h1>
						<p className="text-xl md:text-2xl text-gray-600 dark:text-gray-300 mb-8 max-w-3xl text-center">
							AI-powered competitive intelligence tool that analyzes competitor complaints on
							Twitter to uncover market opportunities and generate qualified leads.
						</p>
						<div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mb-12">
							<Link
								to="/signup"
								className="px-8 py-4 text-lg font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all transform hover:scale-105"
							>
								Start Free Trial
							</Link>
							<a
								href="#features"
								className="px-8 py-4 text-lg font-medium text-blue-600 bg-white rounded-lg border-2 border-blue-600 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all transform hover:scale-105"
							>
								See How It Works
							</a>
						</div>
						<div className="flex justify-center space-x-8 text-gray-600 dark:text-gray-400">
							<div className="flex items-center">
								<svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
									<path
										fillRule="evenodd"
										d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
										clipRule="evenodd"
									/>
								</svg>
								<span>AI-Powered Analysis</span>
							</div>
							<div className="flex items-center">
								<svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
									<path
										fillRule="evenodd"
										d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
										clipRule="evenodd"
									/>
								</svg>
								<span>Actionable Insights</span>
							</div>
							<div className="flex items-center">
								<svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
									<path
										fillRule="evenodd"
										d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
										clipRule="evenodd"
									/>
								</svg>
								<span>Lead Generation</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Features Section */}
			<div id="features" className="bg-white dark:bg-gray-800 py-20">
				<div className="container mx-auto px-6">
					<div className="text-center mb-20">
						<h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
							Turn Complaints into Revenue
						</h2>
						<p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
							Our AI-powered platform helps you identify competitor weaknesses, generate qualified
							leads, and create data-driven strategies for market dominance.
						</p>
					</div>

					<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12">
						{/* Smart Scraping */}
						<div className="bg-slate-50 dark:bg-gray-700 rounded-xl p-8 shadow-lg transform hover:scale-105 transition-all">
							<div className="w-14 h-14 bg-blue-100 dark:bg-blue-900 rounded-full flex items-center justify-center mb-6">
								<svg
									className="w-8 h-8 text-blue-600 dark:text-blue-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
									/>
								</svg>
							</div>
							<h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
								Smart Tweet Scraping
							</h3>
							<p className="text-gray-600 dark:text-gray-300">
								Automatically collect and analyze customer complaints about your competitors,
								focusing on response interactions for maximum insight.
							</p>
						</div>

						{/* AI Analysis */}
						<div className="bg-slate-50 dark:bg-gray-700 rounded-xl p-8 shadow-lg transform hover:scale-105 transition-all">
							<div className="w-14 h-14 bg-blue-100 dark:bg-blue-900 rounded-full flex items-center justify-center mb-6">
								<svg
									className="w-8 h-8 text-blue-600 dark:text-blue-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
									/>
								</svg>
							</div>
							<h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
								Sentiment Classification
							</h3>
							<p className="text-gray-600 dark:text-gray-300">
								Advanced AI algorithms classify tweets into positive and negative sentiments,
								helping you identify critical pain points and opportunities.
							</p>
						</div>

						{/* Lead Generation */}
						<div className="bg-slate-50 dark:bg-gray-700 rounded-xl p-8 shadow-lg transform hover:scale-105 transition-all">
							<div className="w-14 h-14 bg-blue-100 dark:bg-blue-900 rounded-full flex items-center justify-center mb-6">
								<svg
									className="w-8 h-8 text-blue-600 dark:text-blue-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
									/>
								</svg>
							</div>
							<h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
								Lead Enrichment
							</h3>
							<p className="text-gray-600 dark:text-gray-300">
								Export and enrich data for targeted outreach campaigns across multiple platforms,
								turning competitor's unhappy customers into your opportunities.
							</p>
						</div>

						{/* Pain Point Analysis */}
						<div className="bg-slate-50 dark:bg-gray-700 rounded-xl p-8 shadow-lg transform hover:scale-105 transition-all">
							<div className="w-14 h-14 bg-blue-100 dark:bg-blue-900 rounded-full flex items-center justify-center mb-6">
								<svg
									className="w-8 h-8 text-blue-600 dark:text-blue-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
									/>
								</svg>
							</div>
							<h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
								Trend Analysis
							</h3>
							<p className="text-gray-600 dark:text-gray-300">
								AI-powered analysis identifies recurring pain points and trends in customer
								complaints, giving you actionable insights for product development.
							</p>
						</div>

						{/* Strategic Reports */}
						<div className="bg-slate-50 dark:bg-gray-700 rounded-xl p-8 shadow-lg transform hover:scale-105 transition-all">
							<div className="w-14 h-14 bg-blue-100 dark:bg-blue-900 rounded-full flex items-center justify-center mb-6">
								<svg
									className="w-8 h-8 text-blue-600 dark:text-blue-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
									/>
								</svg>
							</div>
							<h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
								Strategic Reports
							</h3>
							<p className="text-gray-600 dark:text-gray-300">
								Generate comprehensive reports with actionable recommendations based on competitor
								weaknesses and market opportunities.
							</p>
						</div>

						{/* Market Intelligence */}
						<div className="bg-slate-50 dark:bg-gray-700 rounded-xl p-8 shadow-lg transform hover:scale-105 transition-all">
							<div className="w-14 h-14 bg-blue-100 dark:bg-blue-900 rounded-full flex items-center justify-center mb-6">
								<svg
									className="w-8 h-8 text-blue-600 dark:text-blue-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
									/>
								</svg>
							</div>
							<h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
								Market Intelligence
							</h3>
							<p className="text-gray-600 dark:text-gray-300">
								Stay ahead of the competition by understanding their weaknesses and leveraging them
								for your market positioning and growth.
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* Call to Action */}
			<div className="bg-blue-600 dark:bg-blue-800 py-16">
				<div className="container mx-auto px-6 text-center">
					<h2 className="text-3xl md:text-4xl font-bold text-white mb-8">
						Ready to Transform Competitor Weaknesses into Your Strengths?
					</h2>
					<Link
						to="/signup"
						className="inline-block px-8 py-4 text-lg font-medium text-blue-600 bg-white rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition-all transform hover:scale-105"
					>
						Start Your Free Trial
					</Link>
				</div>
			</div>

			{/* Footer */}
			<footer className="bg-gray-50 dark:bg-gray-900 py-12">
				<div className="container mx-auto px-6">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div className="mb-8 md:mb-0">
							<div className="text-3xl font-bold mb-2">
								<span className="text-blue-600">X</span>pose
							</div>
							<p className="text-gray-600 dark:text-gray-400">
								Competitive Intelligence & Lead Generation
							</p>
						</div>
						<div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
							<a
								href="#features"
								className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
							>
								Features
							</a>
							<Link
								to="/signin"
								className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
							>
								Sign In
							</Link>
							<Link
								to="/signup"
								className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
							>
								Get Started
							</Link>
						</div>
					</div>
					<div className="mt-8 pt-8 border-t border-gray-200 dark:border-gray-700 text-center text-gray-600 dark:text-gray-400">
						&copy; {new Date().getFullYear()} Xpose. All rights reserved.
					</div>
				</div>
			</footer>
		</div>
	);
};

export default LandingPage;
