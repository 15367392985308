import { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Cog6ToothIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { AppPage } from './Sidebar';

export const Header = ({ navigateTo }: { navigateTo: (page: AppPage) => void }) => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [isProfileOpen, setIsProfileOpen] = useState(false);
	const profileRef = useRef<HTMLDivElement>(null);
	const { user, signOut } = useAuth();

	// Close profile menu when clicking outside
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
				setIsProfileOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	return (
		<div className="bg-white dark:bg-gray-800 md:bg-gray-50 md:dark:bg-gray-900 shadow-sm md:shadow-none py-4 px-4 flex items-center justify-between md:justify-end md:left-64 left-0 fixed top-0 right-0 z-10">
			<button
				onClick={() => setSidebarOpen(!sidebarOpen)}
				className="md:hidden p-2 rounded-lg text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
			>
				<svg
					className="w-6 h-6"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M4 6h16M4 12h16M4 18h16"
					/>
				</svg>
			</button>

			<div className="flex items-center space-x-4">
				{/* Profile Menu */}
				<div className="relative" ref={profileRef}>
					<button
						onClick={() => setIsProfileOpen(!isProfileOpen)}
						className="flex items-center space-x-2 p-1.5 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
					>
						<div className="w-10 h-10 rounded-full bg-gradient-to-r from-purple-400 to-blue-500 flex items-center justify-center text-white font-medium">
							{user?.email?.[0].toUpperCase()}
						</div>
						<svg
							className={`w-4 h-4 text-gray-600 dark:text-gray-300 transition-transform duration-200 ${
								isProfileOpen ? 'transform rotate-180' : ''
							}`}
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M19 9l-7 7-7-7"
							/>
						</svg>
					</button>

					{/* Dropdown Menu */}
					{isProfileOpen && (
						<div className="absolute right-0 mt-2 w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 py-2">
							{/* User Info */}
							<div className="px-4 py-3 border-b border-gray-200 dark:border-gray-700">
								<p className="text-sm font-medium text-gray-900 dark:text-white">{user?.email}</p>
							</div>

							{/* Menu Items */}
							<div className="py-1">
								{/*<button
									onClick={() => {
										navigateTo('settings');
										setIsProfileOpen(false);
									}}
									className="flex items-center space-x-2 w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
								>
									<Cog6ToothIcon className="w-4 h-4" />
									<span>Settings</span>
								</button>*/}
								<button
									onClick={() => {
										signOut();
										setIsProfileOpen(false);
										// Redirect to landing page by reloading the app
										window.location.reload();
									}}
									className="flex items-center space-x-2 w-full px-4 py-2 text-sm text-red-600 dark:text-red-400 hover:bg-gray-100 dark:hover:bg-gray-700"
								>
									<ArrowRightOnRectangleIcon className="w-4 h-4" />
									<span>Sign Out</span>
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
