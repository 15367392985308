import React from 'react';
import LoadingSpinner from './LoadingSpinner';
import ProgressBar from './ProgressBar';

interface ReportGenerationButtonProps {
	onGenerate: () => void;
	disabled: boolean;
	loading: boolean;
	hasApiKey?: boolean;
	progress: {
		status: string;
		message: string;
		progress: number;
		stage?: string;
	} | null;
}

const ReportGenerationButton: React.FC<ReportGenerationButtonProps> = ({
	onGenerate,
	disabled,
	loading,
	hasApiKey = true,
	progress
}) => {
	const isDisabled = disabled || loading || !hasApiKey;

	const getTooltip = () => {
		if (!hasApiKey)
			return 'OpenAI API key is required. Please add your API key in the Settings page.';
		if (loading) return 'Report generation in progress...';
		if (disabled) return 'Report generation not available';
		return '';
	};

	return (
		<div className="relative">
			<button
				onClick={onGenerate}
				disabled={isDisabled}
				className={`px-4 py-2 rounded-lg flex items-center justify-center w-[200px] h-[40px] ${
					isDisabled
						? 'bg-gray-300 text-gray-500 cursor-not-allowed'
						: 'bg-green-600 hover:bg-green-700 text-white'
				}`}
				title={getTooltip()}
			>
				{loading ? (
					<>
						<LoadingSpinner size="sm" />
						<span className="ml-2">Generating...</span>
					</>
				) : (
					'Generate Report'
				)}
			</button>

			{loading && progress && (
				<div className="absolute -bottom-10 left-0 w-[200px]">
					<ProgressBar progress={progress.progress} />
					<div className="text-xs text-gray-600 dark:text-gray-400 mt-1 truncate">
						{progress.message}
						{progress.stage && <span className="ml-1">({progress.stage})</span>}
					</div>
				</div>
			)}
		</div>
	);
};

export default ReportGenerationButton;
