import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useUserProfile } from '../../hooks/useUserProfile';
import Layout from '../../components/layout/Layout';
import ScraperForm from '../../components/ScraperForm';
import ResultsList from '../../components/ResultsList';
import AnalysisButton from '../../components/AnalysisButton';
import ClassificationButton from '../../components/ClassificationButton';
import PainPointsPage from '../../components/analysis/PainPointsPage';
import MetaAnalysisPage from '../../components/meta-analysis/MetaAnalysisPage';
import Settings from '../../components/Settings';
import { Card, CardBody } from '../../components/ui/ScraperUI';
import { AppPage } from '../../components/layout/Sidebar';
import { AnalysisResponse, ClassificationResponse } from '../../types/analysisTypes';

// Define the Tweet data interface
export interface TweetData {
	id: string;
	author: string;
	handle: string;
	content: string;
	timestamp: string;
	likes: number;
	retweets: number;
	replies: number;
	tweet_url: string;
	mentioned_user: string | null;
	user_profile?: {
		name: string;
		handle: string;
		profile_url: string;
		profile_image: string;
	};
	sentiment?: 'positive' | 'negative';
}

// Scrape progress interface
export interface ScrapeProgress {
	status: string;
	message: string;
	progress: number;
	currentTweets?: number;
	scrollCount?: number;
	reachedBottom?: boolean;
}

// Define the API URL directly
const API_URL = process.env.REACT_APP_API_URL;

const DashboardPage: React.FC = () => {
	const navigate = useNavigate();
	const { user, loading: authLoading } = useAuth();
	const { profile, loading: profileLoading } = useUserProfile();

	// Redirect to sign-in if not logged in
	useEffect(() => {
		if (!user && !authLoading) {
			navigate('/signin');
		}
	}, [user, authLoading, navigate]);

	// Dark mode state
	const [darkMode, setDarkMode] = useState(() => {
		// Check local storage or system preference
		const savedMode = localStorage.getItem('darkMode');
		if (savedMode !== null) {
			return savedMode === 'true';
		}
		return window.matchMedia('(prefers-color-scheme: dark)').matches;
	});

	// Navigation state
	const [currentPage, setCurrentPage] = useState<AppPage>('scraper');

	// OpenAI API key state
	const [openAIApiKey, setOpenAIApiKey] = useState<string>('');

	// Update OpenAI API key from profile when it loads
	useEffect(() => {
		if (profile?.openai_key) {
			setOpenAIApiKey(profile.openai_key);
		}
	}, [profile]);

	// Form state
	const [authToken, setAuthToken] = useState('');
	const [profileUrl, setProfileUrl] = useState('');
	const [scrapedProfileHandle, setScrapedProfileHandle] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [results, setResults] = useState<TweetData[]>([]);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const [stopReason, setStopReason] = useState<string | null>(null);
	const [stats, setStats] = useState<any>(null);
	const [showForm, setShowForm] = useState(true);

	// Progress state for SSE
	const [scrapeProgress, setScrapeProgress] = useState<ScrapeProgress | null>(null);
	const [useSSE, setUseSSE] = useState(true); // Default to using SSE

	// Classification state
	const [classificationLoading, setClassificationLoading] = useState(false);
	const [classificationError, setClassificationError] = useState<string | null>(null);
	const [classificationData, setClassificationData] = useState<ClassificationResponse | null>(null);
	const [hasClassified, setHasClassified] = useState(false);
	const [classificationProgress, setClassificationProgress] = useState<{
		status: string;
		message: string;
		progress: number;
		stage?: string;
	} | null>(null);

	// Analysis state
	const [analysisLoading, setAnalysisLoading] = useState(false);
	const [analysisError, setAnalysisError] = useState<string | null>(null);
	const [analysisData, setAnalysisData] = useState<AnalysisResponse | null>(null);
	const [showAnalysis, setShowAnalysis] = useState(false);
	const [hasAnalyzed, setHasAnalyzed] = useState(false);
	const [analysisProgress, setAnalysisProgress] = useState<{
		status: string;
		message: string;
		progress: number;
		stage?: string;
	} | null>(null);

	// Scraper settings
	const [timeout, setTimeout] = useState(300);
	const [minTweets, setMinTweets] = useState(200);
	const [maxScrolls, setMaxScrolls] = useState(100);
	const [useMinTweetsMode, setUseMinTweetsMode] = useState(true);
	const [useDateMode, setUseDateMode] = useState(false);
	const [cutoffDate, setCutoffDate] = useState(() => {
		// Default to 30 days ago
		const date = new Date();
		date.setDate(date.getDate() - 30);
		return date.toISOString().split('T')[0];
	});

	// State to track if we're showing the pain points page
	const [showPainPointsPage, setShowPainPointsPage] = useState(false);

	// Apply dark mode class to document
	useEffect(() => {
		if (darkMode) {
			document.documentElement.classList.add('dark');
		} else {
			document.documentElement.classList.remove('dark');
		}
		localStorage.setItem('darkMode', darkMode.toString());
	}, [darkMode]);

	// Debug classification results
	useEffect(() => {
		if (hasClassified) {
			console.log('Classification state updated. Results:', results);
			console.log('Positive tweets:', results.filter((t) => t.sentiment === 'positive').length);
			console.log('Negative tweets:', results.filter((t) => t.sentiment === 'negative').length);
			console.log('Unclassified tweets:', results.filter((t) => !t.sentiment).length);
		}
	}, [hasClassified, results]);

	// Handle saving OpenAI API key
	const handleSaveApiKey = (apiKey: string) => {
		setOpenAIApiKey(apiKey);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (loading) return;

		// Reset state
		setLoading(true);
		setError(null);
		setSuccessMessage(null);
		setResults([]);
		setScrapeProgress(null);
		setStopReason(null);
		setStats(null);
		setHasClassified(false);
		setClassificationData(null);
		setHasAnalyzed(false);
		setAnalysisData(null);

		// Extract username from profile URL
		const usernameMatch = profileUrl.match(/twitter\.com\/([^/]+)|x\.com\/([^/]+)/);
		const username = usernameMatch ? usernameMatch[1] || usernameMatch[2] : null;

		if (!username) {
			setError('Invalid profile URL. Please enter a valid Twitter/X profile URL.');
			setLoading(false);
			return;
		}

		// Store the profile handle for later use in exports
		setScrapedProfileHandle(username);

		// Prepare request body
		const requestBody = {
			auth_token: authToken,
			profile_url: profileUrl,
			scraper_settings: {
				timeout_seconds: timeout,
				min_tweets: minTweets,
				max_scrolls: maxScrolls,
				use_min_tweets_mode: useMinTweetsMode,
				use_date_mode: useDateMode,
				cutoff_date: useDateMode ? cutoffDate : null
			}
		};

		// Use the SSE utility to create a POST connection
		import('../../utils/sseUtils')
			.then(({ createSSEConnection }) => {
				const cleanup = createSSEConnection(
					`${API_URL}/scrape`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: requestBody
					},
					(data) => {
						// Handle messages
						if (data.type === 'progress') {
							setScrapeProgress(data);
						} else if (data.type === 'complete') {
							setResults(data.data || []);
							setSuccessMessage(`Found ${data.data?.length || 0} tweets mentioning ${username}`);
							setStopReason(data.stop_reason);
							setStats(data.stats);
							setShowForm(false);
							setLoading(false);
						} else if (data.type === 'error') {
							setError(data.message || 'An error occurred during scraping');
							setLoading(false);
						}
					},
					(error) => {
						// Handle errors
						setError('Failed to connect to the server. Please try again.');
						setLoading(false);
					}
				);

				// Return cleanup function
				return cleanup;
			})
			.catch((error) => {
				setError('Failed to load the scraper. Please refresh the page and try again.');
				setLoading(false);
			});
	};

	const handleClassify = () => {
		if (classificationLoading || !results.length || hasClassified) return;

		setClassificationLoading(true);
		setClassificationError(null);
		setClassificationProgress(null);

		// Prepare request body
		const requestBody = {
			tweets: results,
			openai_api_key: openAIApiKey
		};

		// Use the SSE utility to create a POST connection
		import('../../utils/sseUtils')
			.then(({ createSSEConnection }) => {
				const cleanup = createSSEConnection(
					`${API_URL}/classify`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: requestBody
					},
					(data) => {
						// Handle messages
						if (data.type === 'progress') {
							setClassificationProgress(data);
						} else if (data.type === 'complete') {
							// Store the classification data
							setClassificationData(data);

							console.log('Classification data:', data);

							// Update the results with sentiment information
							if (
								data &&
								data.classification_result &&
								data.classification_result.classifications
							) {
								const updatedResults = [...results];
								data.classification_result.classifications.forEach(
									(classification: { tweet_id: string; sentiment: 'positive' | 'negative' }) => {
										const tweetIndex = updatedResults.findIndex(
											(t) => t.id === classification.tweet_id
										);
										if (tweetIndex !== -1) {
											updatedResults[tweetIndex] = {
												...updatedResults[tweetIndex],
												sentiment: classification.sentiment
											};
										}
									}
								);

								// Update the results state with the new array
								setResults(updatedResults);

								// Log the updated results
								console.log('Updated results with sentiment:', updatedResults);
								console.log(
									'Positive tweets:',
									updatedResults.filter((t) => t.sentiment === 'positive').length
								);
								console.log(
									'Negative tweets:',
									updatedResults.filter((t) => t.sentiment === 'negative').length
								);
							}

							// Mark classification as complete
							setHasClassified(true);
							setClassificationLoading(false);
						} else if (data.type === 'error') {
							setClassificationError(data.message || 'An error occurred during classification');
							setClassificationLoading(false);
						}
					},
					(error) => {
						// Handle errors
						setClassificationError('Failed to connect to the server. Please try again.');
						setClassificationLoading(false);
					}
				);

				// Return cleanup function
				return cleanup;
			})
			.catch((error) => {
				setClassificationError(
					'Failed to load the classifier. Please refresh the page and try again.'
				);
				setClassificationLoading(false);
			});
	};

	const handleAnalyze = () => {
		if (analysisLoading || !results.length) return;

		// Check if classification has been done
		if (!hasClassified) {
			setError('Please classify tweets first before generating pain point analysis.');
			return;
		}

		setAnalysisLoading(true);
		setAnalysisError(null);
		setAnalysisProgress(null);

		// Filter only negative tweets for analysis
		const negativeTweets = results.filter((tweet) => tweet.sentiment === 'negative');

		if (negativeTweets.length === 0) {
			setError('No negative tweets found for pain point analysis.');
			setAnalysisLoading(false);
			return;
		}

		// Log the negative tweets being analyzed
		console.log('Analyzing negative tweets:', negativeTweets.length);

		// Prepare request body
		const requestBody = {
			tweets: negativeTweets,
			openai_api_key: openAIApiKey
		};

		// Use the SSE utility to create a POST connection
		import('../../utils/sseUtils')
			.then(({ createSSEConnection }) => {
				const cleanup = createSSEConnection(
					`${API_URL}/analyze`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: requestBody
					},
					(data) => {
						console.log('Analysis data:', data);
						// Handle messages
						if (data.type === 'progress') {
							setAnalysisProgress(data);
						} else if (data.type === 'complete') {
							setAnalysisData(data);
							setHasAnalyzed(true);
							setAnalysisLoading(false);
						} else if (data.type === 'error') {
							setAnalysisError(data.message || 'An error occurred during analysis');
							setAnalysisLoading(false);
						}
					},
					(error) => {
						// Handle errors
						setAnalysisError('Failed to connect to the server. Please try again.');
						setAnalysisLoading(false);
					}
				);

				// Return cleanup function
				return cleanup;
			})
			.catch((error) => {
				setAnalysisError('Failed to load the analyzer. Please refresh the page and try again.');
				setAnalysisLoading(false);
			});
	};

	const viewAnalysis = () => {
		setShowAnalysis(true);
	};

	const closeAnalysis = () => {
		setShowAnalysis(false);
	};

	const getStopReasonText = (reason: string): string => {
		switch (reason) {
			case 'min_tweets_reached':
				return `Reached the minimum number of tweets (${minTweets})`;
			case 'max_scrolls_reached':
				return `Reached the maximum number of scrolls (${maxScrolls})`;
			case 'bottom_reached':
				return 'Reached the bottom of the page';
			case 'date_cutoff_reached':
				return `Reached the date cutoff (${cutoffDate})`;
			default:
				return 'Scraping completed';
		}
	};

	const resetScraper = () => {
		setShowForm(true);
		setResults([]);
		setScrapeProgress(null);
		setSuccessMessage(null);
		setStopReason(null);
		setStats(null);
		setHasClassified(false);
		setClassificationData(null);
		setHasAnalyzed(false);
		setAnalysisData(null);
		setShowAnalysis(false);
		setShowPainPointsPage(false);
	};

	const navigateTo = (page: AppPage) => {
		setCurrentPage(page);
		if (page !== 'scraper') {
			setShowAnalysis(false);
			setShowPainPointsPage(false);
		}
	};

	// If still loading auth, show a loading spinner
	if (authLoading) {
		return (
			<div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
				<div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
			</div>
		);
	}

	return (
		<div className={`${darkMode ? 'dark' : ''}`}>
			<Layout
				currentPage={currentPage}
				navigateTo={navigateTo}
				darkMode={darkMode}
				setDarkMode={setDarkMode}
			>
				{currentPage === 'scraper' && (
					<div className="container mx-auto px-4 py-8">
						{showForm ? (
							<Card>
								<CardBody>
									<ScraperForm
										authToken={authToken}
										setAuthToken={setAuthToken}
										profileUrl={profileUrl}
										setProfileUrl={setProfileUrl}
										timeout={timeout}
										setTimeout={setTimeout}
										minTweets={minTweets}
										setMinTweets={setMinTweets}
										maxScrolls={maxScrolls}
										setMaxScrolls={setMaxScrolls}
										useMinTweetsMode={useMinTweetsMode}
										setUseMinTweetsMode={setUseMinTweetsMode}
										useDateMode={useDateMode}
										setUseDateMode={setUseDateMode}
										cutoffDate={cutoffDate}
										setCutoffDate={setCutoffDate}
										useSSE={useSSE}
										setUseSSE={setUseSSE}
										loading={loading}
										error={error}
										onSubmit={handleSubmit}
										scrapeProgress={scrapeProgress}
									/>
								</CardBody>
							</Card>
						) : showAnalysis ? (
							<PainPointsPage
								analysisData={analysisData}
								tweets={results.filter((tweet) => tweet.sentiment === 'negative')}
								darkMode={darkMode}
								setDarkMode={setDarkMode}
								onClose={closeAnalysis}
								profileHandle={scrapedProfileHandle}
							/>
						) : showPainPointsPage ? (
							<PainPointsPage
								analysisData={analysisData}
								tweets={results.filter((tweet) => tweet.sentiment === 'negative')}
								darkMode={darkMode}
								setDarkMode={setDarkMode}
								onClose={() => setShowPainPointsPage(false)}
								profileHandle={scrapedProfileHandle}
							/>
						) : (
							<div className="space-y-6">
								<div className="flex flex-col md:items-center md:justify-between gap-4 w-full">
									<div className="w-full">
										<h1 className="text-2xl font-bold text-gray-900 dark:text-white">
											Results for @{scrapedProfileHandle}
										</h1>
										{successMessage && (
											<p className="text-green-600 dark:text-green-400 mt-1">{successMessage}</p>
										)}
										{stopReason && (
											<p className="text-gray-600 dark:text-gray-400 mt-1">
												{getStopReasonText(stopReason)}
											</p>
										)}
									</div>
									<div className="flex flex-col gap-2 w-full">
										<ClassificationButton
											onClassify={handleClassify}
											disabled={!results.length || classificationLoading || hasClassified}
											loading={classificationLoading}
											hasApiKey={!!openAIApiKey}
											progress={classificationProgress}
										/>
										<AnalysisButton
											onAnalyze={handleAnalyze}
											onViewAnalysis={viewAnalysis}
											loading={analysisLoading}
											disabled={!results.length || analysisLoading || !hasClassified}
											hasAnalyzed={hasAnalyzed}
											hasApiKey={!!openAIApiKey}
											progress={analysisProgress}
										/>

										<button
											onClick={resetScraper}
											className="h-min text-nowrap px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
										>
											New Search
										</button>
									</div>
								</div>

								<ResultsList
									tweets={results}
									analysisData={classificationData}
									profileHandle={scrapedProfileHandle}
								/>
							</div>
						)}
					</div>
				)}

				{currentPage === 'meta-analysis' && (
					<div className="container mx-auto px-4 py-8">
						<MetaAnalysisPage darkMode={darkMode} setDarkMode={setDarkMode} />
					</div>
				)}

				{currentPage === 'settings' && (
					<div className="container mx-auto px-4 py-8">
						<Settings onSave={handleSaveApiKey} initialApiKey={openAIApiKey} />
					</div>
				)}
			</Layout>
		</div>
	);
};

export default DashboardPage;
