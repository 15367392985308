import React from 'react';
import ProgressBar from './ProgressBar';

interface ScrapeProgress {
	status: string;
	message: string;
	progress: number;
	currentTweets?: number;
	scrollCount?: number;
	reachedBottom?: boolean;
}

interface ScraperFormProps {
	authToken: string;
	setAuthToken: (value: string) => void;
	profileUrl: string;
	setProfileUrl: (value: string) => void;
	onSubmit: (e: React.FormEvent) => void;
	loading: boolean;
	error?: string | null;
	// New props for scraper settings
	timeout: number;
	setTimeout: (value: number) => void;
	minTweets: number;
	setMinTweets: (value: number) => void;
	maxScrolls: number;
	setMaxScrolls: (value: number) => void;
	useMinTweetsMode: boolean;
	setUseMinTweetsMode: (value: boolean) => void;
	useDateMode: boolean;
	setUseDateMode: (value: boolean) => void;
	cutoffDate: string;
	setCutoffDate: (value: string) => void;
	// Progress prop
	scrapeProgress: ScrapeProgress | null;
	// SSE props
	useSSE: boolean;
	setUseSSE: (value: boolean) => void;
}

const ScraperForm: React.FC<ScraperFormProps> = ({
	authToken,
	setAuthToken,
	profileUrl,
	setProfileUrl,
	onSubmit,
	loading,
	error,
	timeout,
	setTimeout,
	minTweets,
	setMinTweets,
	maxScrolls,
	setMaxScrolls,
	useMinTweetsMode,
	setUseMinTweetsMode,
	useDateMode,
	setUseDateMode,
	cutoffDate,
	setCutoffDate,
	scrapeProgress,
	useSSE,
	setUseSSE
}) => {
	return (
		<div className="">
			<h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
				Find Twitter Mentions
			</h2>
			<p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
				This tool finds tweets where other users have mentioned a specific Twitter profile in their
				replies. Enter the profile URL of the account you want to check mentions for.
			</p>

			<form onSubmit={onSubmit}>
				<div className="mb-4">
					<label
						htmlFor="authToken"
						className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
					>
						Twitter Auth Token
					</label>
					<input
						type="password"
						id="authToken"
						value={authToken}
						onChange={(e) => setAuthToken(e.target.value)}
						className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
						placeholder="Enter your Twitter auth_token cookie value"
						required
					/>
					<p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
						Required to access Twitter. Find this in your browser cookies after logging into
						Twitter.
					</p>
				</div>

				<div className="mb-4">
					<label
						htmlFor="profileUrl"
						className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
					>
						Twitter Profile URL
					</label>
					<input
						type="text"
						id="profileUrl"
						value={profileUrl}
						onChange={(e) => setProfileUrl(e.target.value)}
						className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
						placeholder="https://twitter.com/username or https://x.com/username"
						required
					/>
					<p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
						Enter the profile URL of the account you want to find mentions for.
					</p>
				</div>

				<div className="mb-6 space-y-4 p-4 bg-gray-50 dark:bg-gray-700 rounded-md">
					<div className="mb-4">
						<div className="flex items-center justify-between">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
								Scraping Strategy
							</label>
						</div>
						<div className="mt-2 flex flex-col space-y-2">
							<label className="inline-flex items-center">
								<input
									type="radio"
									className="form-radio text-primary"
									name="scrapeStrategy"
									value="minTweets"
									checked={useMinTweetsMode && !useDateMode}
									onChange={() => {
										setUseMinTweetsMode(true);
										setUseDateMode(false);
									}}
								/>
								<span className="ml-2 text-gray-700 dark:text-gray-300">
									Stop after collecting minimum tweets
								</span>
							</label>
							<label className="inline-flex items-center">
								<input
									type="radio"
									className="form-radio text-primary"
									name="scrapeStrategy"
									value="timeout"
									checked={!useMinTweetsMode && !useDateMode}
									onChange={() => {
										setUseMinTweetsMode(false);
										setUseDateMode(false);
									}}
								/>
								<span className="ml-2 text-gray-700 dark:text-gray-300">
									Stop after timeout (return all found)
								</span>
							</label>
							<label className="inline-flex items-center">
								<input
									type="radio"
									className="form-radio text-primary"
									name="scrapeStrategy"
									value="dateMode"
									checked={useDateMode}
									onChange={() => {
										setUseMinTweetsMode(false);
										setUseDateMode(true);
									}}
								/>
								<span className="ml-2 text-gray-700 dark:text-gray-300">Stop at cutoff date</span>
							</label>
						</div>
					</div>

					{useMinTweetsMode && !useDateMode ? (
						<div>
							<label
								htmlFor="minTweets"
								className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
							>
								Minimum Tweets to Collect: {minTweets}
							</label>
							<input
								type="range"
								id="minTweets"
								min="5"
								max="2000"
								step="5"
								value={minTweets}
								onChange={(e) => setMinTweets(parseInt(e.target.value))}
								className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg appearance-none cursor-pointer"
							/>
							<div className="flex justify-between text-xs text-gray-500 dark:text-gray-400 mt-1">
								<span>5 tweets</span>
								<span>2000 tweets</span>
							</div>
							<p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
								For large collections (500+ tweets), the scraper may take several minutes to
								complete. Twitter's rate limits may affect performance when fetching very large
								datasets.
							</p>
						</div>
					) : !useMinTweetsMode && !useDateMode ? (
						<div>
							<label
								htmlFor="timeout"
								className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
							>
								Maximum Time (seconds): {timeout}
							</label>
							<input
								type="range"
								id="timeout"
								min="30"
								max="900"
								step="5"
								value={timeout}
								onChange={(e) => setTimeout(parseInt(e.target.value))}
								className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg appearance-none cursor-pointer"
							/>
							<div className="flex justify-between text-xs text-gray-500 dark:text-gray-400 mt-1">
								<span>30 seconds</span>
								<span>15 minutes</span>
							</div>
						</div>
					) : (
						<div>
							<label
								htmlFor="cutoffDate"
								className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
							>
								Cutoff Date
							</label>
							<input
								type="date"
								id="cutoffDate"
								value={cutoffDate}
								onChange={(e) => setCutoffDate(e.target.value)}
								className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
							/>
							<p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
								The scraper will stop collecting tweets once it reaches tweets older than this date.
							</p>
						</div>
					)}

					<div>
						<label
							htmlFor="maxScrolls"
							className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
						>
							Max Scrolls: {maxScrolls}
						</label>
						<input
							type="range"
							id="maxScrolls"
							min="5"
							max="500"
							step="5"
							value={maxScrolls}
							onChange={(e) => setMaxScrolls(parseInt(e.target.value))}
							className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg appearance-none cursor-pointer"
						/>
						<div className="flex justify-between text-xs text-gray-500 dark:text-gray-400 mt-1">
							<span>5 scrolls</span>
							<span>500 scrolls</span>
						</div>
						<p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
							Limits how far down the page to scroll, regardless of other settings.
						</p>
					</div>
				</div>

				<button
					type="submit"
					disabled={loading}
					className="w-full bg-primary hover:bg-primary-dark text-white font-medium py-2 px-4 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50 disabled:cursor-not-allowed"
				>
					{loading ? 'Searching...' : 'Find Mentions'}
				</button>
			</form>

			{/* Progress bar */}
			{loading && scrapeProgress && (
				<div className="mt-6">
					<div className="mb-2">
						<ProgressBar progress={scrapeProgress.progress} />
					</div>
					<div className="flex justify-between items-center text-sm">
						<span className="text-gray-700 dark:text-gray-300">{scrapeProgress.message}</span>
						<span className="font-medium text-gray-900 dark:text-white">
							{scrapeProgress.progress.toFixed(0)}%
						</span>
					</div>
					{scrapeProgress.currentTweets !== undefined && (
						<div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
							{scrapeProgress.reachedBottom && (
								<span className="ml-2 text-yellow-600 dark:text-yellow-400">
									(Reached bottom of page)
								</span>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ScraperForm;
