import React, { ButtonHTMLAttributes, ReactNode } from 'react';

interface SliderProps {
	min: number;
	max: number;
	step: number;
	value: number;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Slider: React.FC<SliderProps> = ({ min, max, step, value, onChange }) => {
	const percentage = ((value - min) / (max - min)) * 100;

	return (
		<div className="relative">
			<style>
				{`
					.slider {
						-webkit-appearance: none;
						width: 100%;
						height: 6px;
						border-radius: 5px;
						background: linear-gradient(to right, #2563EB 0%, #2563EB ${percentage}%, #E5E7EB ${percentage}%, #E5E7EB 100%);
						outline: none;
					}
					
					.slider::-webkit-slider-thumb {
						-webkit-appearance: none;
						appearance: none;
						width: 18px;
						height: 18px;
						border-radius: 50%;
						background: #2563EB;
						cursor: pointer;
						box-shadow: 0 0 8px rgba(37, 99, 235, 0.5);
						transition: all 0.2s ease;
					}
					
					.slider::-webkit-slider-thumb:hover {
						transform: scale(1.1);
						box-shadow: 0 0 12px rgba(37, 99, 235, 0.7);
					}
					
					.slider::-moz-range-thumb {
						width: 18px;
						height: 18px;
						border-radius: 50%;
						background: #2563EB;
						cursor: pointer;
						box-shadow: 0 0 8px rgba(37, 99, 235, 0.5);
						transition: all 0.2s ease;
						border: none;
					}
					
					.slider::-moz-range-thumb:hover {
						transform: scale(1.1);
						box-shadow: 0 0 12px rgba(37, 99, 235, 0.7);
					}

					.dark .slider {
						background: linear-gradient(to right, #2563EB 0%, #2563EB ${percentage}%, #4B5563 ${percentage}%, #4B5563 100%);
					}
				`}
			</style>
			<input
				type="range"
				min={min}
				max={max}
				step={step}
				value={value}
				onChange={onChange}
				className="slider w-full"
			/>
		</div>
	);
};

interface SwitchProps {
	checked: boolean;
	onChange: () => void;
	label?: string;
	disabled?: boolean;
}

export const Switch: React.FC<SwitchProps> = ({ checked, onChange, label, disabled = false }) => {
	return (
		<label className="inline-flex items-center cursor-pointer">
			<div className="relative">
				<input
					type="checkbox"
					className="sr-only"
					checked={checked}
					onChange={onChange}
					disabled={disabled}
				/>
				<div
					className={`w-12 h-6 rounded-full transition-colors duration-300 ${
						checked
							? 'bg-primary shadow-md'
							: 'bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600'
					} ${disabled ? 'opacity-50' : ''}`}
				></div>
				<div
					className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-300 transform ${
						checked ? 'translate-x-6' : ''
					}`}
				></div>
			</div>
			{label && <span className="ml-3 text-sm text-gray-700 dark:text-gray-300">{label}</span>}
		</label>
	);
};

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'primary' | 'secondary' | 'outline' | 'ghost';
	size?: 'sm' | 'md' | 'lg';
	icon?: ReactNode;
	children?: ReactNode;
	className?: string;
}

export const Button: React.FC<ButtonProps> = ({
	variant = 'primary',
	size = 'md',
	icon,
	children,
	className = '',
	...props
}) => {
	const baseClasses =
		'inline-flex items-center justify-center font-medium rounded-md transition-all duration-200';

	const sizeClasses = {
		sm: 'px-3 py-2 text-sm',
		md: 'px-4 py-2.5 text-base',
		lg: 'px-6 py-3 text-lg'
	};

	const variantClasses = {
		primary: 'bg-primary hover:bg-primary-dark text-white shadow-sm hover:shadow',
		secondary:
			'bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600',
		outline:
			'bg-transparent border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800',
		ghost:
			'bg-transparent hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-700 dark:text-gray-300'
	};

	const disabledClasses = props.disabled ? 'opacity-50 cursor-not-allowed' : '';

	return (
		<button
			className={`${baseClasses} ${sizeClasses[size]} ${variantClasses[variant]} ${disabledClasses} ${className}`}
			{...props}
		>
			{children}
			{icon && <span className="ml-2">{icon}</span>}
		</button>
	);
};

interface BadgeProps {
	variant?: 'default' | 'success' | 'warning' | 'error';
	children: ReactNode;
	className?: string;
}

export const Badge: React.FC<BadgeProps> = ({ variant = 'default', children, className = '' }) => {
	const variantClasses = {
		default: 'bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-300',
		success: 'bg-green-100 dark:bg-green-900/30 text-green-800 dark:text-green-300',
		warning: 'bg-yellow-100 dark:bg-yellow-900/30 text-yellow-800 dark:text-yellow-300',
		error: 'bg-red-100 dark:bg-red-900/30 text-red-800 dark:text-red-300'
	};

	return (
		<span
			className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${variantClasses[variant]} ${className}`}
		>
			{children}
		</span>
	);
};

interface CardProps {
	children: ReactNode;
	className?: string;
}

export const Card: React.FC<CardProps> = ({ children, className = '' }) => {
	return (
		<div
			className={`bg-white dark:bg-gray-800 rounded-lg shadow border border-gray-200 dark:border-gray-700 overflow-hidden ${className}`}
		>
			{children}
		</div>
	);
};

interface CardHeaderProps {
	children: ReactNode;
	className?: string;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ children, className = '' }) => {
	return (
		<div className={`px-6 py-4 border-b border-gray-200 dark:border-gray-700 ${className}`}>
			{children}
		</div>
	);
};

interface CardBodyProps {
	children: ReactNode;
	className?: string;
}

export const CardBody: React.FC<CardBodyProps> = ({ children, className = '' }) => {
	return <div className={`p-6 ${className}`}>{children}</div>;
};

interface CardFooterProps {
	children: ReactNode;
	className?: string;
}

export const CardFooter: React.FC<CardFooterProps> = ({ children, className = '' }) => {
	return (
		<div className={`px-6 py-4 border-t border-gray-200 dark:border-gray-700 ${className}`}>
			{children}
		</div>
	);
};
