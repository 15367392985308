import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import EmailVerification from './EmailVerification';

// Auth view types
type AuthView = 'signIn' | 'signUp' | 'forgotPassword' | 'resetPassword' | 'emailVerification';

interface AuthProps {
	initialView?: AuthView;
	verificationToken?: string;
	verificationEmail?: string;
}

const Auth: React.FC<AuthProps> = ({
	initialView = 'signIn',
	verificationToken,
	verificationEmail
}) => {
	// State
	const [currentView, setCurrentView] = useState<AuthView>(initialView);

	// Auth context
	const { user } = useAuth();

	// Set initial view based on props
	useEffect(() => {
		if (initialView) {
			setCurrentView(initialView);
		}
	}, [initialView]);

	// Render the appropriate auth view
	const renderAuthView = () => {
		switch (currentView) {
			case 'signIn':
				return (
					<SignIn
						onSignUp={() => setCurrentView('signUp')}
						onForgotPassword={() => setCurrentView('forgotPassword')}
					/>
				);
			case 'signUp':
				return <SignUp onSignIn={() => setCurrentView('signIn')} />;
			case 'forgotPassword':
				return <ForgotPassword onBackToSignIn={() => setCurrentView('signIn')} />;
			case 'resetPassword':
				return <ResetPassword onBackToSignIn={() => setCurrentView('signIn')} />;
			case 'emailVerification':
				return (
					<EmailVerification
						token={verificationToken || ''}
						email={verificationEmail || ''}
						onBackToSignIn={() => setCurrentView('signIn')}
					/>
				);
			default:
				return (
					<SignIn
						onSignUp={() => setCurrentView('signUp')}
						onForgotPassword={() => setCurrentView('forgotPassword')}
					/>
				);
		}
	};

	return (
		<div className="min-h-screen flex items-center justify-center px-4 py-12 bg-gray-50 dark:bg-gray-900">
			<div className="w-full max-w-md">{renderAuthView()}</div>
		</div>
	);
};

export default Auth;
