import React, { useMemo } from 'react';
import { PainPointsAnalysis } from '../../types/analysisTypes';
import { TweetData } from '../../pages/dashboard/DashboardPage';

interface CategoryDetailsViewProps {
	painPointsAnalysis: PainPointsAnalysis;
	selectedCategoryName: string | undefined;
	tweets: TweetData[];
}

const CategoryDetailsView: React.FC<CategoryDetailsViewProps> = ({
	painPointsAnalysis,
	selectedCategoryName,
	tweets
}) => {
	const selectedCategory = useMemo(() => {
		if (!selectedCategoryName || !painPointsAnalysis.categories) return null;

		return painPointsAnalysis.categories.find(
			(category) => category.category === selectedCategoryName
		);
	}, [painPointsAnalysis, selectedCategoryName]);

	if (!selectedCategory) {
		return (
			<div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 text-center">
				<p className="text-gray-500 dark:text-gray-400">Select a category to view details</p>
			</div>
		);
	}

	// Get tweets for this category
	const categoryTweets = tweets.filter((tweet) => selectedCategory.tweet_ids.includes(tweet.id));

	return (
		<div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 overflow-hidden">
			<div className="p-4 border-b border-gray-100 dark:border-gray-700 bg-gray-50 dark:bg-gray-800/80">
				<h3 className="font-medium text-gray-700 dark:text-gray-300">
					{selectedCategory.category} Details
				</h3>
			</div>

			<div className="p-6 space-y-6">
				{/* Category Overview */}
				<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
					<div className="bg-indigo-50 dark:bg-indigo-900/30 rounded-lg p-4">
						<h4 className="text-sm font-medium text-indigo-700 dark:text-indigo-300 mb-2">
							Frequency
						</h4>
						<p className="text-2xl font-bold text-indigo-800 dark:text-indigo-200">
							{selectedCategory.tweet_ids.length} tweets
						</p>
						<p className="text-sm text-indigo-600 dark:text-indigo-400">
							{selectedCategory.frequency}% of total
						</p>
					</div>

					<div className="bg-amber-50 dark:bg-amber-900/30 rounded-lg p-4">
						<h4 className="text-sm font-medium text-amber-700 dark:text-amber-300 mb-2">
							Severity
						</h4>
						<div className="flex items-center">
							{[...Array(5)].map((_, i) => (
								<svg
									key={i}
									className={`w-6 h-6 ${
										i < (selectedCategory.severity || 0)
											? 'text-amber-500 dark:text-amber-400'
											: 'text-gray-200 dark:text-gray-600'
									}`}
									fill="currentColor"
									viewBox="0 0 20 20"
								>
									<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
								</svg>
							))}
							<span className="ml-2 text-lg font-medium text-amber-700 dark:text-amber-300">
								{selectedCategory.severity || 0}/5
							</span>
						</div>
					</div>

					<div className="bg-emerald-50 dark:bg-emerald-900/30 rounded-lg p-4">
						<h4 className="text-sm font-medium text-emerald-700 dark:text-emerald-300 mb-2">
							Subcategories
						</h4>
						<p className="text-2xl font-bold text-emerald-800 dark:text-emerald-200">
							{selectedCategory.subcategories?.length || 0}
						</p>
					</div>
				</div>

				{/* Description */}
				{selectedCategory.description && (
					<div className="bg-gray-50 dark:bg-gray-700/30 rounded-lg p-4">
						<h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
							Description
						</h4>
						<p className="text-gray-600 dark:text-gray-400">{selectedCategory.description}</p>
					</div>
				)}

				{/* Subcategories */}
				{selectedCategory.subcategories && selectedCategory.subcategories.length > 0 && (
					<div>
						<h4 className="text-lg font-medium text-gray-800 dark:text-gray-200 mb-3">
							Subcategories
						</h4>
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
							{selectedCategory.subcategories.map((subcategory, index) => (
								<div
									key={`subcategory-${index}`}
									className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-4 shadow-sm"
								>
									<h5 className="font-medium text-gray-800 dark:text-gray-200 mb-2">
										{subcategory.name}
									</h5>
									{subcategory.description && (
										<p className="text-sm text-gray-600 dark:text-gray-400 mb-3 line-clamp-2">
											{subcategory.description}
										</p>
									)}
									<div className="flex justify-between items-center text-sm">
										<div className="flex items-center">
											<span className="text-indigo-600 dark:text-indigo-400 font-medium">
												{subcategory.tweet_ids.length} tweets
											</span>
										</div>
										{typeof subcategory.severity === 'number' && (
											<div className="flex items-center">
												<svg
													className="w-4 h-4 text-amber-500 dark:text-amber-400 mr-1"
													fill="currentColor"
													viewBox="0 0 20 20"
												>
													<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
												</svg>
												<span className="text-gray-700 dark:text-gray-300">
													{subcategory.severity}/5
												</span>
											</div>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				)}

				{/* Related Tweets */}
				<div>
					<h4 className="text-lg font-medium text-gray-800 dark:text-gray-200 mb-3">
						Related Tweets ({categoryTweets.length})
					</h4>
					<div className="space-y-3 max-h-[500px] overflow-y-auto pr-2">
						{categoryTweets.length > 0 ? (
							categoryTweets.map((tweet) => (
								<div
									key={tweet.id}
									className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-4 shadow-sm"
								>
									<div className="flex items-start">
										<div className="flex-shrink-0 mr-3">
											{tweet.user_profile?.profile_image ? (
												<img
													src={tweet.user_profile.profile_image}
													alt={tweet.user_profile.handle}
													className="w-10 h-10 rounded-full"
												/>
											) : (
												<div className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
													<span className="text-gray-500 dark:text-gray-400 text-sm font-bold">
														{tweet.handle?.charAt(0).toUpperCase() || '?'}
													</span>
												</div>
											)}
										</div>
										<div className="flex-1 min-w-0">
											<div className="flex items-center mb-1">
												<p className="text-sm font-medium text-gray-900 dark:text-white truncate">
													{tweet.user_profile?.name || tweet.author || 'Unknown'}
												</p>
												<p className="ml-1 text-sm text-gray-500 dark:text-gray-400 truncate">
													@{tweet.handle || 'unknown'}
												</p>
											</div>
											<p className="text-sm text-gray-800 dark:text-gray-200 whitespace-pre-line">
												{tweet.content}
											</p>
											<div className="mt-2 text-xs text-gray-500 dark:text-gray-400">
												{new Date(tweet.timestamp).toLocaleDateString()}
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<p className="text-gray-500 dark:text-gray-400 text-center py-4">
								No tweets available for this category
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CategoryDetailsView;
