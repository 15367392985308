import React, { useState, useEffect, useRef } from 'react';
import { PainPointsAnalysis } from '../../types/analysisTypes';
import { TweetData } from '../../pages/dashboard/DashboardPage';

interface PainPointsBarChartProps {
	painPointsAnalysis: PainPointsAnalysis;
	tweets: TweetData[];
	onSelectTweet: (tweetIds: string[], categoryName?: string) => void;
}

// Modern tooltip component with enhanced styling - same as in PainPointsChart
const ModernTooltip = ({ item }: { item: any }) => {
	return (
		<div className="bg-white dark:bg-gray-800 p-4 rounded-xl shadow-xl border border-gray-100 dark:border-gray-700 max-w-[350px] transform transition-all duration-200 animate-fadeIn backdrop-blur-sm">
			<h3 className="font-bold text-lg mb-2 text-gray-900 dark:text-white">
				{item.name || 'Unknown'}
			</h3>
			{item.description && (
				<p className="text-sm text-gray-600 dark:text-gray-300 mb-3 leading-relaxed">
					{item.description}
				</p>
			)}
			<div className="flex items-center mb-3 bg-gray-50 dark:bg-gray-700 p-2 rounded-lg">
				<span className="text-sm font-medium mr-2 text-gray-700 dark:text-gray-300">
					Frequency:
				</span>
				<span className="text-sm font-bold bg-indigo-100 dark:bg-indigo-900 text-indigo-800 dark:text-indigo-200 px-2 py-1 rounded-md">
					{item.frequency !== undefined ? item.frequency : item.value} issues
				</span>
			</div>
			{typeof item.severity === 'number' && (
				<div className="flex items-center bg-gray-50 dark:bg-gray-700 p-2 rounded-lg">
					<span className="text-sm font-medium mr-2 text-gray-700 dark:text-gray-300">
						Severity:
					</span>
					<div className="flex">
						{[...Array(5)].map((_, i) => (
							<svg
								key={i}
								className={`w-4 h-4 ${
									i < item.severity
										? 'text-red-500 dark:text-red-400 drop-shadow-sm'
										: 'text-gray-200 dark:text-gray-600'
								}`}
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
							</svg>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

const PainPointsBarChart: React.FC<PainPointsBarChartProps> = ({
	painPointsAnalysis,
	tweets,
	onSelectTweet
}) => {
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
	const [hoveredItem, setHoveredItem] = useState<any>(null);
	const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
	const [isLoaded, setIsLoaded] = useState(false);
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const chartHeight = 350; // Fixed chart height in pixels

	// Set loaded state after a small delay to allow for animation
	useEffect(() => {
		// Detailed data logging for debugging
		console.log('Pain points analysis data:', JSON.stringify(painPointsAnalysis, null, 2));
		console.log('Categories count:', painPointsAnalysis.categories.length);

		// Debug each category's data in detail
		painPointsAnalysis.categories.forEach((cat, index) => {
			console.log(
				`Category ${index}: ${cat.category}
				- frequency: ${cat.frequency} (type: ${typeof cat.frequency})
				- tweet_ids: ${cat.tweet_ids.length}
				- severity: ${cat.severity}`
			);
		});

		const timer = setTimeout(() => {
			setIsLoaded(true);
		}, 300);
		return () => clearTimeout(timer);
	}, [painPointsAnalysis]);

	// Get severity color for bars
	const getSeverityColor = (severity: number) => {
		const colors = [
			'bg-yellow-400', // Severity 1 (lightest)
			'bg-orange-400',
			'bg-orange-500',
			'bg-red-500',
			'bg-red-600' // Severity 5 (darkest)
		];
		return colors[Math.min(Math.max(Math.floor(severity) - 1, 0), 4)];
	};

	// Handle bar click
	const handleBarClick = (category: any) => {
		setSelectedCategory(category.category);
		onSelectTweet(category.tweet_ids || [], category.category);
		setHoveredItem(null); // Hide tooltip when clicking
	};

	// Handle mouse enter for tooltip
	const handleMouseEnter = (e: React.MouseEvent, item: any) => {
		setTooltipPosition({
			x: e.clientX,
			y: e.clientY
		});
		setHoveredItem(item);
	};

	// Handle mouse leave for tooltip
	const handleMouseLeave = () => {
		setHoveredItem(null);
	};

	// Determine which value to use for the bar heights
	const getBarValue = (category: any) => {
		// Use tweet count as the value for bar heights
		return category.tweet_ids.length;
	};

	// Find the maximum value for scaling
	const maxValue = painPointsAnalysis.categories.reduce(
		(max, category) => Math.max(max, getBarValue(category)),
		0
	);

	// Sort categories by frequency (tweet count) in descending order
	const sortedCategories = [...painPointsAnalysis.categories].sort((a, b) => {
		return getBarValue(b) - getBarValue(a);
	});

	// Create nice round numbers for the Y-axis
	// Find a nice round number that's just above the max value
	const findNiceMaxValue = (max: number) => {
		// If max is less than 5, use 5 as the max
		if (max <= 5) return 5;

		// For values up to 10, round to the next 5
		if (max <= 10) return Math.ceil(max / 5) * 5;

		// For values up to 50, round to the next 10
		if (max <= 50) return Math.ceil(max / 10) * 10;

		// For larger values, round to the next 50
		if (max <= 100) return Math.ceil(max / 50) * 50;

		// For very large values, round to the next 100
		return Math.ceil(max / 100) * 100;
	};

	// Calculate a nice Y-axis maximum
	const yAxisMax = findNiceMaxValue(maxValue);

	// Generate Y-axis tick values - exactly 5 ticks from 0 to yAxisMax
	const yAxisTicks = [0, 1, 2, 3, 4].map((i) => Math.round((yAxisMax * i) / 4));

	console.log('Max value for scaling:', maxValue, 'Y-axis max:', yAxisMax, 'Ticks:', yAxisTicks);

	// Check if we have any data to display
	if (painPointsAnalysis.categories.length === 0) {
		return (
			<div className="w-full bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 overflow-hidden">
				<div className="p-4 border-b border-gray-100 dark:border-gray-700 bg-gray-50 dark:bg-gray-800/80">
					<h3 className="font-medium text-gray-700 dark:text-gray-300">Pain Points Analysis</h3>
				</div>
				<div className="p-8 text-center">
					<p className="text-gray-500 dark:text-gray-400">No pain points data available</p>
				</div>
			</div>
		);
	}

	return (
		<div className="w-full bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 overflow-hidden">
			<div className="p-4 border-b border-gray-100 dark:border-gray-700 bg-gray-50 dark:bg-gray-800/80 flex justify-between items-center">
				<h3 className="font-medium text-gray-700 dark:text-gray-300">Pain Points Analysis</h3>
				<div className="text-xs text-gray-500 dark:text-gray-400">
					Sorted by frequency (highest first)
				</div>
			</div>

			<div className="p-6 min-h-[500px]">
				{isLoaded ? (
					<div className="flex flex-col h-[450px]">
						{/* Y-axis and bars container */}
						<div className="flex h-full">
							{/* Empty space where Y-axis labels were */}
							<div className="w-10"></div>

							{/* Bars container with horizontal scroll */}
							<div className="flex-1 relative h-[380px] overflow-hidden">
								{/* Grid lines */}
								<div className="absolute inset-0 flex flex-col justify-between pointer-events-none">
									{/* Horizontal grid lines for visual reference */}
									{[...Array(5)].map((_, i) => (
										<div
											key={`grid-${i}`}
											className="border-t border-gray-200 dark:border-gray-700 w-full h-0"
										></div>
									))}
								</div>

								{/* Scrollable container for bars */}
								<div
									ref={scrollContainerRef}
									className="h-full overflow-x-auto pb-2 flex items-end"
									style={{ minWidth: '100%' }}
								>
									<div className="flex items-end space-x-4 min-w-max px-2">
										{/* Bars - using sorted categories */}
										{sortedCategories.map((category, index) => {
											// Get the value for this bar
											const value = getBarValue(category);

											// Calculate bar height in pixels (not percentage)
											// Use a minimum of 4px to ensure visibility
											const pixelHeight = Math.max(Math.round((value / yAxisMax) * chartHeight), 4);

											const isSelected = selectedCategory === category.category;

											console.log(
												`Bar ${index}: ${category.category}, height: ${pixelHeight}px, value: ${value}, yAxisMax: ${yAxisMax}`
											);

											return (
												<div
													key={`bar-${index}`}
													className="flex flex-col items-center"
													style={{ width: '80px' }}
												>
													<div
														className={`w-full max-w-[80px] rounded-t-md cursor-pointer transition-all duration-300 ${getSeverityColor(
															category.severity
														)} ${
															isSelected
																? 'ring-2 ring-indigo-500 dark:ring-indigo-400 ring-offset-1 dark:ring-offset-gray-800'
																: ''
														}`}
														style={{
															height: `${pixelHeight}px`
														}}
														onClick={() => handleBarClick(category)}
														onMouseEnter={(e) =>
															handleMouseEnter(e, {
																name: category.category,
																description: category.description,
																value: category.tweet_ids.length,
																frequency: category.frequency,
																severity: category.severity
															})
														}
														onMouseLeave={handleMouseLeave}
													>
														{/* Value display inside the bar */}
														<div className="text-[10px] text-white text-center font-bold px-1 truncate pt-1">
															{value}
														</div>
													</div>
													<div className="mt-2 text-xs text-gray-600 dark:text-gray-400 w-full text-center truncate px-1">
														{category.category}
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>

						{/* X-axis label */}
						<div className="mt-8 text-center text-sm text-gray-500 dark:text-gray-400">
							Pain Point Categories
						</div>
					</div>
				) : (
					<div className="flex justify-center items-center h-[400px]">
						<div className="animate-pulse flex flex-col items-center">
							<div className="h-32 w-32 bg-gray-200 dark:bg-gray-700 rounded-lg mb-4"></div>
							<div className="h-4 w-48 bg-gray-200 dark:bg-gray-700 rounded"></div>
						</div>
					</div>
				)}

				{/* Tooltip */}
				{hoveredItem && (
					<div
						className="fixed z-50 pointer-events-none"
						style={{
							left: `${tooltipPosition.x + 15}px`,
							top: `${tooltipPosition.y + 15}px`
						}}
					>
						<ModernTooltip item={hoveredItem} />
					</div>
				)}
			</div>
		</div>
	);
};

export default PainPointsBarChart;
