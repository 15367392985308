import React, { useState, useEffect } from 'react';
import { Card, CardBody } from './ui/ScraperUI';
import { useUserProfile } from '../hooks/useUserProfile';

interface SettingsProps {
	onSave: (apiKey: string) => void;
	initialApiKey?: string;
}

const Settings: React.FC<SettingsProps> = ({ onSave, initialApiKey = '' }) => {
	const [apiKey, setApiKey] = useState(initialApiKey);
	const [isSaving, setIsSaving] = useState(false);
	const [saveMessage, setSaveMessage] = useState<{
		type: 'success' | 'error';
		text: string;
	} | null>(null);

	// Use our custom hook to manage the user profile
	const { profile, loading: isLoading, updateOpenAIKey } = useUserProfile();

	// Update the API key when the profile is loaded
	useEffect(() => {
		if (profile?.openai_key) {
			setApiKey(profile.openai_key);
			// Also update the parent component's state
			onSave(profile.openai_key);
		}
	}, [profile, onSave]);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsSaving(true);
		setSaveMessage(null);

		try {
			// Save to database using our custom hook
			const { success, error } = await updateOpenAIKey(apiKey);

			if (!success) {
				throw error || new Error('Failed to save API key');
			}

			// Call the onSave prop with the API key (for localStorage or other client-side storage)
			onSave(apiKey);

			// Show success message
			setSaveMessage({
				type: 'success',
				text: 'API key saved successfully!'
			});
		} catch (error) {
			// Show error message
			setSaveMessage({
				type: 'error',
				text: error instanceof Error ? error.message : 'Failed to save API key. Please try again.'
			});
			console.error('Error saving API key:', error);
		} finally {
			setIsSaving(false);
		}
	};

	// Clear success message after 3 seconds
	useEffect(() => {
		if (saveMessage?.type === 'success') {
			const timer = setTimeout(() => {
				setSaveMessage(null);
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [saveMessage]);

	return (
		<div className="max-w-3xl mx-auto">
			<h1 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">Settings</h1>

			<Card>
				<CardBody>
					<h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
						API Configuration
					</h2>

					{isLoading ? (
						<div className="flex justify-center py-4">
							<div className="w-8 h-8 border-t-2 border-b-2 border-indigo-500 rounded-full animate-spin"></div>
						</div>
					) : (
						<form onSubmit={handleSubmit}>
							<div className="mb-6">
								<label
									htmlFor="openai-api-key"
									className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300"
								>
									OpenAI API Key
								</label>
								<div className="relative">
									<input
										id="openai-api-key"
										type="password"
										value={apiKey}
										onChange={(e) => setApiKey(e.target.value)}
										placeholder="sk-..."
										className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg 
                           focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500
                           bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
									/>
								</div>
								<p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
									Your API key is stored securely in our database and never shared with third
									parties.
								</p>
							</div>

							<div className="flex items-center justify-between">
								<button
									type="submit"
									disabled={isSaving}
									className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-medium rounded-lg
                         focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                         disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
								>
									{isSaving ? 'Saving...' : 'Save Settings'}
								</button>

								{saveMessage && (
									<div
										className={`ml-4 text-sm ${
											saveMessage.type === 'success'
												? 'text-green-600 dark:text-green-400'
												: 'text-red-600 dark:text-red-400'
										}`}
									>
										{saveMessage.text}
									</div>
								)}
							</div>
						</form>
					)}
				</CardBody>
			</Card>
		</div>
	);
};

export default Settings;
