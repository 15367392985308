import React, { createContext, useContext, useEffect, useState } from 'react';
import {
	AuthUser,
	getCurrentUser,
	setupAuthListener,
	signIn,
	signOut,
	signUp,
	resetPassword,
	updatePassword,
	verifyEmail
} from '../utils/supabaseClient';

// Define the shape of the auth context
interface AuthContextType {
	user: AuthUser | null;
	loading: boolean;
	signUp: (
		email: string,
		password: string,
		name: string
	) => Promise<{
		data: any;
		error: any;
		success: boolean;
	}>;
	signIn: (
		email: string,
		password: string
	) => Promise<{
		data: any;
		error: any;
		success: boolean;
	}>;
	signOut: () => Promise<{ error: any; success: boolean }>;
	resetPassword: (email: string) => Promise<{
		data: any;
		error: any;
		success: boolean;
	}>;
	updatePassword: (newPassword: string) => Promise<{
		data: any;
		error: any;
		success: boolean;
	}>;
	verifyEmail: (
		token: string,
		email: string
	) => Promise<{
		data: any;
		error: any;
		success: boolean;
	}>;
}

// Create the auth context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Auth provider component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [user, setUser] = useState<AuthUser | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	// Initialize auth state
	useEffect(() => {
		const initializeAuth = async () => {
			try {
				// Check for existing session
				const { user: currentUser } = await getCurrentUser();
				setUser(currentUser || null);
			} catch (error) {
				console.error('Error initializing auth:', error);
			} finally {
				setLoading(false);
			}
		};

		initializeAuth();

		// Set up auth state change listener
		const { data: authListener } = setupAuthListener((user) => {
			setUser(user);
			setLoading(false);
		});

		// Clean up listener on unmount
		return () => {
			authListener?.subscription.unsubscribe();
		};
	}, []);

	// Wrap auth functions to include success flag
	const handleSignUp = async (email: string, password: string, name: string) => {
		try {
			const { data, error } = await signUp(email, password, name);
			return { data, error, success: !error };
		} catch (error) {
			console.error('Sign up error:', error);
			return { data: null, error, success: false };
		}
	};

	const handleSignIn = async (email: string, password: string) => {
		try {
			const { data, error } = await signIn(email, password);
			return { data, error, success: !error };
		} catch (error) {
			console.error('Sign in error:', error);
			return { data: null, error, success: false };
		}
	};

	const handleSignOut = async () => {
		try {
			const { error } = await signOut();
			return { error, success: !error };
		} catch (error) {
			console.error('Sign out error:', error);
			return { error, success: false };
		}
	};

	const handleResetPassword = async (email: string) => {
		try {
			const { data, error } = await resetPassword(email);
			return { data, error, success: !error };
		} catch (error) {
			console.error('Reset password error:', error);
			return { data: null, error, success: false };
		}
	};

	const handleUpdatePassword = async (newPassword: string) => {
		try {
			const { data, error } = await updatePassword(newPassword);
			return { data, error, success: !error };
		} catch (error) {
			console.error('Update password error:', error);
			return { data: null, error, success: false };
		}
	};

	const handleVerifyEmail = async (token: string, email: string) => {
		try {
			const { data, error } = await verifyEmail(token, email);
			return { data, error, success: !error };
		} catch (error) {
			console.error('Verify email error:', error);
			return { data: null, error, success: false };
		}
	};

	// Context value
	const value = {
		user,
		loading,
		signUp: handleSignUp,
		signIn: handleSignIn,
		signOut: handleSignOut,
		resetPassword: handleResetPassword,
		updatePassword: handleUpdatePassword,
		verifyEmail: handleVerifyEmail
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Custom hook to use the auth context
export const useAuth = (): AuthContextType => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};

export default AuthContext;
