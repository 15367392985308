import React from 'react';
import { Auth } from '../../components/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useEffect } from 'react';

const SignInPage: React.FC = () => {
	const navigate = useNavigate();
	const { user, loading } = useAuth();

	// Redirect to dashboard if already logged in
	useEffect(() => {
		if (user && !loading) {
			navigate('/dashboard');
		}
	}, [user, loading, navigate]);

	return (
		<div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900 px-4">
			<div className="w-full max-w-md">
				<Auth initialView="signIn" />
			</div>
		</div>
	);
};

export default SignInPage;
