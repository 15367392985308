import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { AuthCard, AuthHeader, AuthBody, Input, Button, Alert, Link } from '../ui/AuthUI';

interface SignInProps {
	onSignUp: () => void;
	onForgotPassword: () => void;
}

const SignIn: React.FC<SignInProps> = ({ onSignUp, onForgotPassword }) => {
	// Form state
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	// Validation state
	const [errors, setErrors] = useState<{
		email?: string;
		password?: string;
	}>({});

	// Form submission state
	const [isLoading, setIsLoading] = useState(false);
	const [formError, setFormError] = useState<string | null>(null);

	// Auth context
	const { signIn } = useAuth();

	// Validate form
	const validateForm = (): boolean => {
		const newErrors: {
			email?: string;
			password?: string;
		} = {};

		// Validate email
		if (!email.trim()) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			newErrors.email = 'Email is invalid';
		}

		// Validate password
		if (!password) {
			newErrors.password = 'Password is required';
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	// Handle form submission
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		// Reset form status
		setFormError(null);

		// Validate form
		if (!validateForm()) {
			return;
		}

		// Submit form
		setIsLoading(true);

		try {
			const { success, error } = await signIn(email, password);

			if (!success) {
				setFormError(error.message || 'Failed to sign in. Please check your credentials.');
			}
			// If successful, the auth context will update and redirect
		} catch (error) {
			setFormError('An unexpected error occurred. Please try again.');
			console.error('Sign in error:', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<AuthCard>
			<AuthHeader title="Sign In" subtitle="Welcome back to Xpose" />
			<AuthBody>
				{formError && (
					<Alert type="error" message={formError} onDismiss={() => setFormError(null)} />
				)}

				<form onSubmit={handleSubmit}>
					<Input
						id="email"
						label="Email Address"
						type="email"
						placeholder="john@example.com"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						error={errors.email}
						required
					/>

					<Input
						id="password"
						label="Password"
						type="password"
						placeholder="••••••••"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						error={errors.password}
						required
					/>

					<div className="flex justify-end mb-4">
						<Link
							onClick={onForgotPassword}
							href="#"
							variant="secondary"
							className="text-xs text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300"
						>
							Forgot password?
						</Link>
					</div>

					<div className="mt-6">
						<Button type="submit" isLoading={isLoading}>
							Sign In
						</Button>
					</div>
				</form>

				<div className="mt-4 text-center">
					<p className="text-sm text-gray-600 dark:text-gray-400">
						Don't have an account?{' '}
						<Link onClick={onSignUp} href="#" variant="primary">
							Sign up
						</Link>
					</p>
				</div>
			</AuthBody>
		</AuthCard>
	);
};

export default SignIn;
