import React from 'react';

// Form container
export const AuthCard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<div className="w-full max-w-md mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
			{children}
		</div>
	);
};

// Form header
export const AuthHeader: React.FC<{ title: string; subtitle?: string }> = ({ title, subtitle }) => {
	return (
		<div className="px-6 py-4 bg-indigo-600 dark:bg-indigo-700">
			<h2 className="text-xl font-bold text-white">{title}</h2>
			{subtitle && <p className="mt-1 text-sm text-indigo-100">{subtitle}</p>}
		</div>
	);
};

// Form body
export const AuthBody: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return <div className="px-6 py-6">{children}</div>;
};

// Form input
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label: string;
	error?: string;
}

export const Input: React.FC<InputProps> = ({ label, error, ...props }) => {
	return (
		<div className="mb-4">
			<label
				className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
				htmlFor={props.id}
			>
				{label}
			</label>
			<input
				className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 
                  bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                  ${
										error
											? 'border-red-500 focus:border-red-500'
											: 'border-gray-300 dark:border-gray-600'
									}`}
				{...props}
			/>
			{error && <p className="mt-1 text-sm text-red-600 dark:text-red-400">{error}</p>}
		</div>
	);
};

// Submit button
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	isLoading?: boolean;
	variant?: 'primary' | 'secondary' | 'danger';
}

export const Button: React.FC<ButtonProps> = ({
	children,
	isLoading,
	variant = 'primary',
	...props
}) => {
	const baseClasses =
		'w-full px-4 py-2 rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors';

	const variantClasses = {
		primary:
			'bg-indigo-600 hover:bg-indigo-700 text-white focus:ring-indigo-500 disabled:bg-indigo-400',
		secondary:
			'bg-gray-200 hover:bg-gray-300 text-gray-800 focus:ring-gray-500 disabled:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white',
		danger: 'bg-red-600 hover:bg-red-700 text-white focus:ring-red-500 disabled:bg-red-400'
	};

	return (
		<button
			className={`${baseClasses} ${variantClasses[variant]} ${props.className || ''} ${
				isLoading ? 'opacity-70 cursor-not-allowed' : ''
			}`}
			disabled={isLoading || props.disabled}
			{...props}
		>
			{isLoading ? (
				<div className="flex items-center justify-center">
					<svg
						className="animate-spin -ml-1 mr-2 h-4 w-4 text-current"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<circle
							className="opacity-25"
							cx="12"
							cy="12"
							r="10"
							stroke="currentColor"
							strokeWidth="4"
						></circle>
						<path
							className="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
						></path>
					</svg>
					<span>Loading...</span>
				</div>
			) : (
				children
			)}
		</button>
	);
};

// Form divider with text
export const Divider: React.FC<{ text: string }> = ({ text }) => {
	return (
		<div className="relative my-6">
			<div className="absolute inset-0 flex items-center">
				<div className="w-full border-t border-gray-300 dark:border-gray-600"></div>
			</div>
			<div className="relative flex justify-center text-sm">
				<span className="px-2 bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-400">
					{text}
				</span>
			</div>
		</div>
	);
};

// Alert component
interface AlertProps {
	type: 'success' | 'error' | 'info' | 'warning';
	message: string;
	onDismiss?: () => void;
}

export const Alert: React.FC<AlertProps> = ({ type, message, onDismiss }) => {
	const typeClasses = {
		success:
			'bg-green-50 text-green-800 border-green-200 dark:bg-green-900/30 dark:text-green-200 dark:border-green-800',
		error:
			'bg-red-50 text-red-800 border-red-200 dark:bg-red-900/30 dark:text-red-200 dark:border-red-800',
		info: 'bg-blue-50 text-blue-800 border-blue-200 dark:bg-blue-900/30 dark:text-blue-200 dark:border-blue-800',
		warning:
			'bg-yellow-50 text-yellow-800 border-yellow-200 dark:bg-yellow-900/30 dark:text-yellow-200 dark:border-yellow-800'
	};

	return (
		<div
			className={`p-4 mb-4 rounded-md border ${typeClasses[type]} flex justify-between items-start`}
		>
			<div className="flex-1">{message}</div>
			{onDismiss && (
				<button
					onClick={onDismiss}
					className="ml-3 inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
					aria-label="Dismiss"
				>
					<svg
						className="h-5 w-5"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
							clipRule="evenodd"
						/>
					</svg>
				</button>
			)}
		</div>
	);
};

// Link component
interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	variant?: 'primary' | 'secondary';
}

export const Link: React.FC<LinkProps> = ({ children, variant = 'primary', ...props }) => {
	const variantClasses = {
		primary:
			'text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300',
		secondary: 'text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300'
	};

	return (
		<a
			className={`${variantClasses[variant]} text-sm font-medium cursor-pointer ${
				props.className || ''
			}`}
			{...props}
		>
			{children}
		</a>
	);
};
