import React, { useState, useCallback, useMemo } from 'react';
import { PainPointsAnalysis } from '../../types/analysisTypes';
import { TweetData } from '../../pages/dashboard/DashboardPage';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

interface PainPointsListViewProps {
	painPointsAnalysis: PainPointsAnalysis;
	tweets: TweetData[];
	onSelectTweet: (tweetIds: string[], categoryName?: string) => void;
}

// Define AutoSizer render prop types
interface AutoSizerProps {
	height: number;
	width: number;
}

const PainPointsListView: React.FC<PainPointsListViewProps> = ({
	painPointsAnalysis,
	tweets,
	onSelectTweet
}) => {
	const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

	// Memoize the categories to prevent unnecessary re-renders
	const categories = useMemo(() => painPointsAnalysis.categories, [painPointsAnalysis.categories]);

	const toggleCategory = useCallback(
		(category: string, e?: React.MouseEvent) => {
			if (e) {
				e.stopPropagation();
			}

			const newExpanded = new Set(expandedCategories);
			if (newExpanded.has(category)) {
				newExpanded.delete(category);
			} else {
				newExpanded.add(category);
			}
			setExpandedCategories(newExpanded);
		},
		[expandedCategories]
	);

	const handleCategoryClick = useCallback(
		(category: string, tweetIds: string[]) => {
			// Always select the category and show its tweets
			setSelectedCategory(category);
			onSelectTweet(tweetIds, category);
		},
		[onSelectTweet]
	);

	const handleSubcategoryClick = useCallback(
		(tweetIds: string[], categoryName: string, subcategoryName: string, e: React.MouseEvent) => {
			e.stopPropagation();
			onSelectTweet(tweetIds, `${categoryName}: ${subcategoryName}`);
		},
		[onSelectTweet]
	);

	// Function to get severity color
	const getSeverityColor = useCallback((severity: number) => {
		const colors = [
			'bg-yellow-100 dark:bg-yellow-900/30', // Severity 1 (lightest)
			'bg-orange-100 dark:bg-orange-900/30',
			'bg-orange-200 dark:bg-orange-800/40',
			'bg-red-200 dark:bg-red-800/40',
			'bg-red-300 dark:bg-red-700/50' // Severity 5 (darkest)
		];
		return colors[Math.min(Math.max(Math.floor(severity) - 1, 0), 4)];
	}, []);

	// Function to get severity text color
	const getSeverityTextColor = useCallback((severity: number) => {
		const colors = [
			'text-yellow-800 dark:text-yellow-200', // Severity 1 (lightest)
			'text-orange-800 dark:text-orange-200',
			'text-orange-900 dark:text-orange-100',
			'text-red-800 dark:text-red-200',
			'text-red-900 dark:text-red-100' // Severity 5 (darkest)
		];
		return colors[Math.min(Math.max(Math.floor(severity) - 1, 0), 4)];
	}, []);

	// Render a category row
	const CategoryRow = useCallback(
		({ index, style }: { index: number; style: React.CSSProperties }) => {
			const category = categories[index];
			if (!category) return null;

			const hasSubcategories = category.subcategories && category.subcategories.length > 0;
			const isExpanded = expandedCategories.has(category.category);
			const isSelected = selectedCategory === category.category;

			return (
				<div style={style} className="px-4 py-2">
					<div
						className={`flex justify-between items-start p-4 rounded-lg mb-2 cursor-pointer transition-all duration-300 ${
							isSelected
								? 'bg-indigo-50 dark:bg-indigo-900/30 border-l-4 border-indigo-500 dark:border-indigo-400 shadow-md transform scale-[1.01]'
								: 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-750 border-l-4 border-transparent'
						}`}
						onClick={() => handleCategoryClick(category.category, category.tweet_ids)}
					>
						<div className="flex items-center space-x-3 flex-1">
							{hasSubcategories ? (
								<button
									className={`w-7 h-7 rounded-full ${
										isSelected
											? 'bg-indigo-100 dark:bg-indigo-800/50 text-indigo-600 dark:text-indigo-300'
											: 'bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400'
									} flex items-center justify-center hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors`}
									onClick={(e) => toggleCategory(category.category, e)}
									aria-label={isExpanded ? 'Collapse category' : 'Expand category'}
								>
									<svg
										className={`w-4 h-4 transition-transform ${
											isExpanded ? 'transform rotate-180' : ''
										}`}
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M19 9l-7 7-7-7"
										/>
									</svg>
								</button>
							) : (
								<div className="w-7 h-7"></div>
							)}

							<div className="flex-1">
								<div
									className={`font-medium ${
										isSelected
											? 'text-indigo-700 dark:text-indigo-300'
											: 'text-gray-800 dark:text-white'
									} text-base`}
								>
									{category.category}
								</div>
								{category.description && (
									<p className="text-xs text-gray-500 dark:text-gray-400 mt-1 line-clamp-2">
										{category.description}
									</p>
								)}
							</div>
						</div>

						<div className="flex items-center space-x-4">
							<div className="flex items-center space-x-2">
								<span className="text-xs text-gray-500 dark:text-gray-400">Tweets:</span>
								<span
									className={`text-xs font-medium ${
										isSelected
											? 'bg-indigo-200 dark:bg-indigo-800 text-indigo-800 dark:text-indigo-200'
											: 'bg-indigo-100 dark:bg-indigo-900 text-indigo-800 dark:text-indigo-200'
									} px-2 py-0.5 rounded-full`}
								>
									{category.tweet_ids.length}
								</span>
							</div>

							<div className="flex items-center space-x-2">
								<span className="text-xs text-gray-500 dark:text-gray-400">Severity:</span>
								<div className="flex">
									{[...Array(5)].map((_, i) => (
										<svg
											key={i}
											className={`w-3.5 h-3.5 ${
												i < category.severity
													? `${
															isSelected
																? 'text-red-600 dark:text-red-500'
																: 'text-red-500 dark:text-red-400'
													  }`
													: 'text-gray-200 dark:text-gray-600'
											}`}
											fill="currentColor"
											viewBox="0 0 20 20"
										>
											<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
										</svg>
									))}
								</div>
							</div>

							<div className="flex items-center space-x-2">
								<span className="text-xs text-gray-500 dark:text-gray-400">Frequency:</span>
								<span
									className={`text-xs font-medium ${
										isSelected
											? 'bg-blue-200 dark:bg-blue-800 text-blue-800 dark:text-blue-200'
											: 'bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200'
									} px-2 py-0.5 rounded-full`}
								>
									{category.frequency}
								</span>
							</div>
						</div>
					</div>

					{/* Subcategories */}
					{isExpanded && hasSubcategories && (
						<div className="pl-10 pr-4 pb-2 mb-4 bg-gray-50 dark:bg-gray-750/50 border border-gray-100 dark:border-gray-700 rounded-lg">
							<div className="divide-y divide-gray-100 dark:divide-gray-700/50">
								{category.subcategories.map((subcategory, subIndex) => {
									const isSubcategorySelected =
										selectedCategory === `${category.category}: ${subcategory.name}`;

									return (
										<div
											key={`subcategory-${index}-${subIndex}`}
											className={`py-3 px-3 my-2 hover:bg-gray-100/50 dark:hover:bg-gray-700/30 cursor-pointer rounded-md transition-all ${
												isSubcategorySelected
													? 'bg-indigo-50 dark:bg-indigo-900/30 border-l-3 border-indigo-500 dark:border-indigo-400 shadow-sm'
													: ''
											}`}
											onClick={(e) =>
												handleSubcategoryClick(
													subcategory.tweet_ids,
													category.category,
													subcategory.name,
													e
												)
											}
										>
											<div className="flex-1">
												<div
													className={`font-medium text-sm ${
														isSubcategorySelected
															? 'text-indigo-700 dark:text-indigo-300'
															: 'text-gray-700 dark:text-gray-200'
													}`}
												>
													{subcategory.name}
												</div>
												{subcategory.description && (
													<p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5 line-clamp-2">
														{subcategory.description}
													</p>
												)}
											</div>

											<div className="flex items-center space-x-3 mt-2">
												<div className="flex items-center space-x-1">
													<span className="text-xs text-gray-500 dark:text-gray-400">Tweets:</span>
													<span
														className={`text-xs font-medium ${
															isSubcategorySelected
																? 'bg-indigo-200 dark:bg-indigo-800/70 text-indigo-700 dark:text-indigo-300'
																: 'bg-indigo-50 dark:bg-indigo-900/50 text-indigo-700 dark:text-indigo-300'
														} px-1.5 py-0.5 rounded-full`}
													>
														{subcategory.tweet_ids.length}
													</span>
												</div>

												<div className="flex items-center space-x-1">
													<span className="text-xs text-gray-500 dark:text-gray-400">
														Severity:
													</span>
													<span
														className={`text-xs font-medium ${getSeverityColor(
															subcategory.severity
														)} ${getSeverityTextColor(
															subcategory.severity
														)} px-1.5 py-0.5 rounded-full`}
													>
														{subcategory.severity}/5
													</span>
												</div>

												<div className="flex items-center space-x-1">
													<span className="text-xs text-gray-500 dark:text-gray-400">Freq:</span>
													<span
														className={`text-xs font-medium ${
															isSubcategorySelected
																? 'bg-blue-200 dark:bg-blue-800/70 text-blue-700 dark:text-blue-300'
																: 'bg-blue-50 dark:bg-blue-900/50 text-blue-700 dark:text-blue-300'
														} px-1.5 py-0.5 rounded-full`}
													>
														{subcategory.frequency}
													</span>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			);
		},
		[
			categories,
			expandedCategories,
			handleCategoryClick,
			handleSubcategoryClick,
			toggleCategory,
			getSeverityColor,
			getSeverityTextColor,
			selectedCategory
		]
	);

	return (
		<div className="w-full bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 overflow-hidden">
			<div className="p-4 border-b border-gray-100 dark:border-gray-700 bg-gray-50 dark:bg-gray-800/80">
				<h3 className="font-medium text-gray-700 dark:text-gray-300">Pain Points Categories</h3>
			</div>

			<div className="h-[600px]">
				{categories.length > 0 ? (
					<AutoSizer>
						{({ height, width }: AutoSizerProps) => (
							<List
								height={height}
								width={width}
								itemCount={categories.length}
								itemSize={100} // Approximate row height
							>
								{CategoryRow}
							</List>
						)}
					</AutoSizer>
				) : (
					<div className="p-8 text-center">
						<p className="text-gray-500 dark:text-gray-400">No pain points data available</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default PainPointsListView;
