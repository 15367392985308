import React, { useState, useRef, useEffect } from 'react';
import { AnalysisResponse } from '../../types/analysisTypes';
import { TweetData } from '../../pages/dashboard/DashboardPage';
import PainPointsChart from './PainPointsChart';
import PainPointsListView from './PainPointsListView';
import PainPointsBarChart from './PainPointsBarChart';
import CategoryDetailsView from './CategoryDetailsView';
import { exportAnalysisToExcel, exportAnalysisToCSV } from '../../utils/exportUtils';
import { generateReport, getReportProgress } from '../../utils/reportUtils';
import ReportModal from './ReportModal';
import { useUserProfile } from '../../hooks/useUserProfile';
import ReportGenerationButton from '../ReportGenerationButton';

interface PainPointsPageProps {
	analysisData: AnalysisResponse | null;
	tweets: TweetData[];
	darkMode: boolean;
	setDarkMode: (darkMode: boolean) => void;
	onClose: () => void;
	profileHandle?: string;
}

const PainPointsPage: React.FC<PainPointsPageProps> = ({
	analysisData,
	tweets,
	darkMode,
	setDarkMode,
	onClose,
	profileHandle = 'profile'
}) => {
	const [selectedTweetIds, setSelectedTweetIds] = useState<string[]>([]);
	const [selectedCategoryName, setSelectedCategoryName] = useState<string | null>(null);
	const [viewMode, setViewMode] = useState<'list' | 'chart' | 'bar'>('chart');
	const [isExporting, setIsExporting] = useState(false);

	// Add new state for report generation
	const [isReportModalOpen, setIsReportModalOpen] = useState(false);
	const [isGeneratingReport, setIsGeneratingReport] = useState(false);
	const [reportId, setReportId] = useState<string | undefined>(undefined);
	const [reportError, setReportError] = useState<string | undefined>(undefined);
	const [reportGenerated, setReportGenerated] = useState(false);
	const [reportProgress, setReportProgress] = useState<{
		status: string;
		message: string;
		progress: number;
		stage?: string;
	} | null>(null);

	// Get user profile to access OpenAI API key
	const { profile, loading: profileLoading } = useUserProfile();

	// Close export dropdown when clicking outside
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			const dropdown = document.getElementById('export-dropdown');
			if (
				dropdown &&
				!dropdown.contains(event.target as Node) &&
				!event.target?.toString().includes('Export')
			) {
				dropdown.classList.add('hidden');
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	// Check report progress if we have a reportId but report is not yet generated
	useEffect(() => {
		let progressInterval: NodeJS.Timeout | null = null;
		let eventSource: EventSource | null = null;

		const setupEventSource = () => {
			if (!reportId || reportGenerated) return;

			// Close any existing event source
			if (eventSource) {
				eventSource.close();
			}

			// Get the API URL from environment or use default
			const API_URL = process.env.REACT_APP_API_URL || '';

			// Create a new event source for SSE
			eventSource = new EventSource(`${API_URL}/report/progress/${reportId}`);

			console.log(`Setting up EventSource for report ID: ${reportId}`);

			eventSource.onmessage = (event) => {
				try {
					console.log('SSE message received:', event.data);
					const progress = JSON.parse(event.data);
					setReportProgress(progress);

					// If the report is complete, mark it as generated
					if (progress.status === 'completed') {
						setReportGenerated(true);
						setIsGeneratingReport(false);
						eventSource?.close();
						console.log('Report generation completed');
					} else if (progress.status === 'error') {
						setReportError(progress.message);
						setIsGeneratingReport(false);
						eventSource?.close();
						console.log('Report generation error:', progress.message);
					}
				} catch (err) {
					console.error('Error parsing progress data:', err);
				}
			};

			eventSource.onerror = (error) => {
				console.error('SSE connection error:', error);
				eventSource?.close();

				// Fall back to polling if SSE fails
				if (progressInterval === null && !reportGenerated) {
					console.log('Falling back to polling for progress updates');
					progressInterval = setInterval(checkReportProgress, 2000);
				}
			};
		};

		const checkReportProgress = async () => {
			if (!reportId || reportGenerated) return;

			try {
				const progressData = await getReportProgress(reportId);

				if (progressData.success) {
					setReportProgress({
						status: progressData.status || 'unknown',
						message: progressData.message || '',
						progress: progressData.progress || 0,
						stage: progressData.stage || 'unknown'
					});

					// If the report is complete, mark it as generated
					if (progressData.status === 'completed') {
						setReportGenerated(true);
						setIsGeneratingReport(false);

						// Clear the interval once complete
						if (progressInterval) {
							clearInterval(progressInterval);
							progressInterval = null;
						}
					}
				}
			} catch (err) {
				console.error('Error checking report progress:', err);
			}
		};

		if (reportId && !reportGenerated) {
			// Try SSE first
			setupEventSource();
		}

		return () => {
			if (progressInterval) {
				clearInterval(progressInterval);
			}
			if (eventSource) {
				eventSource.close();
			}
		};
	}, [reportId, reportGenerated]);

	const handleSelectTweet = (tweetIds: string[], categoryName: string | null = null) => {
		setSelectedTweetIds(tweetIds);
		setSelectedCategoryName(categoryName);
	};

	const handleExportCSV = async () => {
		if (!analysisData) return;
		try {
			setIsExporting(true);
			await exportAnalysisToCSV(analysisData, profileHandle);
			setIsExporting(false);
		} catch (error) {
			console.error('Error exporting to CSV:', error);
			setIsExporting(false);
		}
	};

	const handleExportExcel = async () => {
		if (!analysisData) return;
		try {
			setIsExporting(true);
			await exportAnalysisToExcel(analysisData, tweets, profileHandle);
			setIsExporting(false);
		} catch (error) {
			console.error('Error exporting to Excel:', error);
			setIsExporting(false);
		}
	};

	const handleGenerateReport = async () => {
		if (!analysisData) return;

		// Get the OpenAI API key from the user profile
		const openAIApiKey = profile?.openai_key;

		// Check if the API key exists
		if (!openAIApiKey) {
			setReportError('OpenAI API key is required. Please add your API key in the Settings page.');
			return;
		}

		setIsGeneratingReport(true);
		setReportError(undefined);
		setReportGenerated(false);
		setReportProgress(null);

		try {
			const companyName = profileHandle || 'Company';
			const result = await generateReport(analysisData, tweets, companyName, openAIApiKey);

			if (result.success) {
				setReportId(result.reportId);
				// Don't set reportGenerated to true yet - we'll wait for the progress updates
			} else {
				setReportError(result.message);
				setIsGeneratingReport(false);
			}
		} catch (error) {
			console.error('Error generating report:', error);
			setReportError('An unexpected error occurred while generating the report.');
			setIsGeneratingReport(false);
		}
	};

	const handleViewReport = () => {
		if (!reportId) return;
		setIsReportModalOpen(true);
	};

	const closeReportModal = () => {
		setIsReportModalOpen(false);
	};

	// Function to cycle through view modes
	const cycleViewMode = () => {
		if (viewMode === 'chart') setViewMode('bar');
		else if (viewMode === 'bar') setViewMode('list');
		else setViewMode('chart');
	};

	// Get view mode icon and text
	const getViewModeInfo = () => {
		switch (viewMode) {
			case 'list':
				return {
					icon: (
						<svg
							className="w-4 h-4 mr-2"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
							/>
						</svg>
					),
					text: 'Chart View'
				};
			case 'bar':
				return {
					icon: (
						<svg
							className="w-4 h-4 mr-2"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
							/>
						</svg>
					),
					text: 'List View'
				};
			default:
				return {
					icon: (
						<svg
							className="w-4 h-4 mr-2"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M4 6h16M4 10h16M4 14h16M4 18h16"
							/>
						</svg>
					),
					text: 'Bar Chart View'
				};
		}
	};

	if (!analysisData || !analysisData.success || !analysisData.pain_points_analysis) {
		return (
			<div className="min-h-screen bg-gray-50 dark:bg-gray-900">
				<div className="container mx-auto px-4 py-8">
					<div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-8 text-center">
						<p className="text-gray-500 dark:text-gray-400">No analysis data available</p>
					</div>
				</div>
			</div>
		);
	}

	const painPointsAnalysis = analysisData.pain_points_analysis;

	// Disable the generate report button if the user doesn't have an OpenAI API key
	const canGenerateReport = !isGeneratingReport && !isReportModalOpen && !!profile?.openai_key;

	return (
		<div className="min-h-screen bg-gray-50 dark:bg-gray-900">
			<div className="container mx-auto px-4 py-8">
				<div className="mb-6 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
					<div>
						<h1 className="text-2xl font-bold text-gray-900 dark:text-white">
							Pain Points Analysis
						</h1>
						<p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
							Analyze and visualize pain points from {tweets.length} tweets
						</p>
					</div>

					<div className="flex flex-wrap gap-2">
						<button
							onClick={cycleViewMode}
							className="h-min px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-750 transition-colors"
						>
							<span className="flex items-center">
								{getViewModeInfo().icon}
								{getViewModeInfo().text}
							</span>
						</button>

						<button
							onClick={onClose}
							className="h-min px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-750 transition-colors"
						>
							<span className="flex items-center">
								<svg
									className="w-4 h-4 mr-2"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M10 19l-7-7m0 0l7-7m-7 7h18"
									/>
								</svg>
								Back to Dashboard
							</span>
						</button>

						{/* Report Button */}
						<div className="relative mb-6">
							{!reportId || !reportGenerated ? (
								<ReportGenerationButton
									onGenerate={handleGenerateReport}
									disabled={!canGenerateReport}
									loading={isGeneratingReport}
									hasApiKey={!!profile?.openai_key}
									progress={reportProgress}
								/>
							) : (
								<button
									onClick={handleViewReport}
									className="px-4 py-2 bg-emerald-600 hover:bg-emerald-700 dark:bg-emerald-700 dark:hover:bg-emerald-800 text-white rounded-lg shadow-sm text-sm font-medium transition-colors flex items-center w-[200px] h-[40px] justify-center"
								>
									<svg
										className="w-4 h-4 mr-2"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
										/>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
										/>
									</svg>
									View Report
								</button>
							)}
						</div>

						{/* Export Dropdown */}
						<div className="relative inline-block">
							<button
								className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-700 dark:hover:bg-indigo-800 text-white rounded-lg shadow-sm text-sm font-medium transition-colors flex items-center"
								onClick={() => {
									const dropdown = document.getElementById('export-dropdown');
									if (dropdown) {
										dropdown.classList.toggle('hidden');
									}
								}}
								disabled={isExporting}
							>
								{isExporting ? (
									<svg
										className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
									>
										<circle
											className="opacity-25"
											cx="12"
											cy="12"
											r="10"
											stroke="currentColor"
											strokeWidth="4"
										></circle>
										<path
											className="opacity-75"
											fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										></path>
									</svg>
								) : (
									<svg
										className="w-4 h-4 mr-2"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
										/>
									</svg>
								)}
								{isExporting ? 'Exporting...' : 'Export'}
							</button>

							<div
								id="export-dropdown"
								className="hidden absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg z-10 border border-gray-200 dark:border-gray-700"
							>
								<ul className="py-1">
									<li>
										<button
											onClick={handleExportCSV}
											className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-750"
											disabled={isExporting}
										>
											Export as CSV
										</button>
									</li>
									<li>
										<button
											onClick={handleExportExcel}
											className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-750"
											disabled={isExporting}
										>
											Export as Excel
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-col gap-6">
					<div>
						{viewMode === 'list' ? (
							<PainPointsListView
								painPointsAnalysis={painPointsAnalysis}
								onSelectTweet={(tweetIds: string[], categoryName?: string) => {
									handleSelectTweet(tweetIds, categoryName || null);
								}}
								tweets={tweets}
							/>
						) : viewMode === 'bar' ? (
							<PainPointsBarChart
								painPointsAnalysis={painPointsAnalysis}
								onSelectTweet={handleSelectTweet}
								tweets={tweets}
							/>
						) : (
							<PainPointsChart
								painPointsAnalysis={painPointsAnalysis}
								onSelectTweet={handleSelectTweet}
								tweets={tweets}
							/>
						)}
					</div>

					<div>
						<CategoryDetailsView
							painPointsAnalysis={painPointsAnalysis}
							selectedCategoryName={selectedCategoryName || undefined}
							tweets={tweets}
						/>
					</div>
				</div>

				{/* Add Report Modal */}
				<ReportModal
					isOpen={isReportModalOpen}
					onClose={closeReportModal}
					reportId={reportId}
					isGenerating={isGeneratingReport}
					error={reportError}
				/>
			</div>
		</div>
	);
};

export default PainPointsPage;
